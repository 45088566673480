import { User } from '@generated/types.d'

export type CurrentUserState = {
  user: Partial<User> | null
  userView: CurrentUserView | null
  accessToken?: string
  features?: any
  hasProductAccess?: boolean
  // access?: (featureCode: string) => { granted: boolean }
}

export enum CurrentUserView {
  ADMINISTRATOR = 'administrator',
  RESPONDENT = 'respondent',
  ONBOARDING = 'onboarding',
  EMPLOYEE_REGISTER = 'new-registration',
}

// actions
export enum CurrentUserActionType {
  UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER',
  RESET_CURRENT_USER = 'RESET_CURRENT_USER',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_USER_VIEW = 'SET_USER_VIEW',
  SET_FEATURES = 'SET_FEATURES',
  SET_ACCESS_CONTROL = 'SET_ACCESS_CONTROL',
}

export type UpdateCurrentUserAction = {
  type: CurrentUserActionType.UPDATE_CURRENT_USER
  payload: {
    user: Partial<User>
  }
}

export type ResetCurrentUserAction = {
  type: CurrentUserActionType.RESET_CURRENT_USER
}

export type SetAccessTokenAction = {
  type: CurrentUserActionType.SET_ACCESS_TOKEN
  payload: {
    accessToken: string
  }
}

export type SetUserViewAction = {
  type: CurrentUserActionType.SET_USER_VIEW
  payload: {
    userView: CurrentUserView
  }
}

export type SetFeaturesAction = {
  type: CurrentUserActionType.SET_FEATURES
  payload: {
    features: any
  }
}

export type SetAccessControlAction = {
  type: CurrentUserActionType.SET_ACCESS_CONTROL
  payload: {
    hasProductAccess: boolean
  }
}

export type CurrentUserAction =
  | UpdateCurrentUserAction
  | ResetCurrentUserAction
  | SetAccessTokenAction
  | SetUserViewAction
  | SetFeaturesAction
  | SetAccessControlAction
