import { QuestionType, SurveyBuilderQuestionMode } from '@generated/types.d'

import { makeVar } from '@apollo/client'

interface ISubcategoryTemplate {
  id?: string
  isSelected?: boolean
  isConfirmed?: boolean
}

interface ISurveyInitialState {
  selectedSubcategoryTemplates?: ISubcategoryTemplate[]
  loadedSubcategoryTemplateIds?: string[]
  loadedQuestionTypes?: Partial<QuestionType>[]
  displayMode?: SurveyBuilderQuestionMode
}

const SurveyInitialState: ISurveyInitialState = {
  selectedSubcategoryTemplates: [],
  loadedSubcategoryTemplateIds: [],
  loadedQuestionTypes: [],
  displayMode: SurveyBuilderQuestionMode.ReadOnly,
}

export const surveyStateVar = makeVar<ISurveyInitialState>(SurveyInitialState)
export const resetSurveyStateVar = () => surveyStateVar(SurveyInitialState)
