import React from 'react'
// src
import { makeVar } from '@common/utils/reactiveVar'
import AsyncStorage from '@react-native-community/async-storage'

import { AuthMfaTypeEnum, User } from '@generated/types.d'
import { CurrentUserState } from './types'
import CurrentUserReducer, { initialCurrentUserState } from './reducer'

const options = {
  storage: AsyncStorage,
  storageKey: 'pq_user',
}

export type UserVarProps = {
  mfaType?: AuthMfaTypeEnum
  view?: string
  hasChangedPassword?: boolean
} & Partial<User>

export const userVar = makeVar<UserVarProps>(null, options)

export type CurrentUserDispatch = (action: any) => void
type CurrentUserProviderProps = { children: React.ReactNode }

const CurrentUserStateContext = React.createContext<CurrentUserState | null>(
  null,
)
const CurrentUserDispatchContext =
  React.createContext<CurrentUserDispatch | null>(null)

const CurrentUserProvider = (props: CurrentUserProviderProps) => {
  const { children } = props
  const [state, dispatch] = React.useReducer(
    CurrentUserReducer,
    initialCurrentUserState,
  )

  return (
    <CurrentUserStateContext.Provider value={state}>
      <CurrentUserDispatchContext.Provider value={dispatch}>
        {children}
      </CurrentUserDispatchContext.Provider>
    </CurrentUserStateContext.Provider>
  )
}

const useCurrentUserState = () => {
  const ctx = React.useContext(CurrentUserStateContext)
  if (ctx === null) {
    throw new Error(
      'useCurrentUserState must be used within a CurrentUserProvider',
    )
  }

  return ctx
}

const useCurrentUserDispatch = () => {
  const ctx = React.useContext(CurrentUserDispatchContext)
  if (ctx === null) {
    throw new Error(
      'useCurrentUserDispatch must be used within a CurrentUserProvider',
    )
  }

  return ctx
}

const useCurrentUser = (): [CurrentUserState, CurrentUserDispatch] => {
  const state = useCurrentUserState()
  const dispatch = useCurrentUserDispatch()

  return [state, dispatch]
}

export { CurrentUserProvider, useCurrentUser }
