import { FieldPolicy } from '@palqee/apollo-client'

export const setFullNameType = (): FieldPolicy => ({
  read: (_, { readField }) => {
    const firstName = readField<string>('firstName')
    const lastName = readField<string>('lastName')

    return `${firstName} ${lastName}`
  },
})
