import { TypePolicies } from '@apollo/client'
import { AuthRoleCodeEnum, UserRole } from '@generated/types.d'
import { ReadFieldFunction } from '@palqee/apollo-client'
import hash from 'object-hash'

/**
 * deprecate this once we move this logic
 * to backend as it should be
 */
export const hasRole = (
  roles: UserRole[],
  readField: ReadFieldFunction,
  role: AuthRoleCodeEnum,
  currentCompanyId?: string,
): boolean => {
  const userId = readField({ fieldName: 'id' })

  return (
    roles?.some((r) => {
      const code = readField<string>('code', r)
      const readCompanyId = readField<string>('companyId', r)
      const readUserId = readField<string>('userId', r)

      // no companyId set on PalqeeManager
      if (role === AuthRoleCodeEnum.PalqeeManager && userId === readUserId) {
        return code === role
      }

      // check against companyId
      if (readCompanyId === currentCompanyId && userId === readUserId) {
        return code === role
      }

      return false
    }) ?? false
  )
}

export const hasRoleByReadField = (
  readField: ReadFieldFunction,
  role: AuthRoleCodeEnum,
  currentCompanyId?: string,
) => {
  const roles = readField<UserRole[]>({ fieldName: 'roles' })
  return hasRole(roles as UserRole[], readField, role, currentCompanyId)
}

export const hashKeyArgs = (args: Record<string, any>) => hash(args)

export const findAllByKey = (obj, keyToFind) => {
  return Object.entries(obj).reduce((acc, [key, value]: [string, any]) => {
    if (key === keyToFind) {
      return acc.concat(value)
    } else if (typeof value === 'object' && value !== null) {
      return acc.concat(findAllByKey(value, keyToFind))
    } else {
      return acc
    }
  }, [])
}

export const cleanProperty = (data, key) => {
  if (!data) return {}
  return { [key]: data }
}

type PoliciesFn = () => TypePolicies
export const composeTypePolicies = (...policies: PoliciesFn[]) => {
  return policies.reduce((acc, policy) => {
    return { ...acc, ...policy() }
  }, {})
}
