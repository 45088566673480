import { createElement, forwardRef, useId, useLayoutEffect } from 'react'
import classNames from 'classnames'
import { DialogHeadingProps } from './types'
import { useFloatingUiContext } from '@palqee/utils'

const DialogHeading = forwardRef<HTMLHeadingElement, DialogHeadingProps>(
  (props, ref) => {
    const { className, as, children, ...rest } = props
    const { setLabelId } = useFloatingUiContext()
    const id = useId()

    // Only sets `aria-labelledby` on the Dialog root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(id)
      return () => setLabelId(undefined)
    }, [id, setLabelId])

    const classes = classNames('pq-dialog__heading', className)

    return createElement(
      as,
      {
        className: classes,
        ...rest,
        id,
        ref,
      },
      children,
    )
  },
)

DialogHeading.displayName = 'DialogHeading'

export { DialogHeading }
