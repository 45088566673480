import { CardContent } from './content'
import { CardHeader } from './header'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { ComponentPropsWithRef, PropsWithChildren, forwardRef } from 'react'
import CardFooter from './footer'

const CardWrapper = styled.div`
  &.pq-card {
    border-radius: 16px;
    background-color: #ffffff;
  }
`

const Card = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ComponentPropsWithRef<'div'>>
>((props, forwardedRef) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-card', className)

  return (
    <CardWrapper className={classes} {...rest} ref={forwardedRef}>
      {children}
    </CardWrapper>
  )
})

Card.displayName = 'Card'

const CardNamespace = Object.assign(Card, {
  Header: CardHeader,
  Content: CardContent,
  Footer: CardFooter,
})

export { CardNamespace as Card }
