import { PropsWithChildren } from 'react'
import { PopperProps } from './types'
import { FloatingUiProvider, useFloatingUi } from '@palqee/utils'

const Popper = (props: PropsWithChildren<PopperProps>) => {
  const { children, ...options } = props

  const popper = useFloatingUi(options)

  return <FloatingUiProvider {...popper}>{children}</FloatingUiProvider>
}

export { Popper }
