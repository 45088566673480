import { CardHeaderActions } from './header-actions'
import { CardHeaderTitle } from './header-title'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

const CardHeader = (
  props: PropsWithChildren<ComponentPropsWithoutRef<'div'>>,
) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-card__header', className)

  return (
    <CardHeaderWrapper className={classes} {...rest}>
      {children}
    </CardHeaderWrapper>
  )
}

const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CardHeaderNamespace = Object.assign(CardHeader, {
  Title: CardHeaderTitle,
  Actions: CardHeaderActions,
})

export { CardHeaderNamespace as CardHeader }
