import {
  ApolloLink,
  DefaultContext,
  HttpLink,
  HttpOptions,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { ApolloClientHeaders } from '/@hooks/use-apollo/types'

const checkGraphqlEnvVarExist = () => {
  if (!process.env.NEXT_PUBLIC_GRAPHQL_API_URL) {
    throw new Error(
      'Invalid / Missing environment variable: "NEXT_PUBLIC_GRAPHQL_API_URL"',
    )
  }
}

/**
 * Link options
 */
export const setOptions = (): HttpOptions => {
  checkGraphqlEnvVarExist()
  return {
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    credentials: 'same-origin',
  }
}

/**
 * Apollo Terminating link
 * 1. hasUpload - use createUploadLink
 * 2. useBatch - use BatchHttpLink
 */
export const createHttpLink = () => {
  const options = setOptions()
  const httpLink = ApolloLink.split(
    (operation) => {
      return !!operation.getContext().hasUpload
    },
    createUploadLink(options),
    ApolloLink.split(
      (operation) => {
        return !!operation.getContext().useBatch
      },
      new BatchHttpLink({
        ...options,
        batchMax: 5,
        // add interval to 700
        // for slow connections
        // @todo improve BE request timing
        batchInterval: 700,
        batchDebounce: true,
        headers: { batch: 'true ' },
      }),
      new HttpLink(options),
    ),
  )

  return httpLink
}

export const setAuthorizationLink = (props: ApolloClientHeaders) => {
  const { lang = '', authorization: sessionAuth = '' } = props ?? {}
  return setContext(
    async (_, args: DefaultContext & { headers: Record<string, string> }) => {
      const { headers = {} } = args
      const xPalqeeLang = headers?.lang ?? lang

      // we also allow headers?.authorization to be set
      // from a query or mutation context
      const authorization = headers?.authorization ?? sessionAuth

      const promise = new Promise((resolve) => {
        const commonHeaders = {
          'X-PALQEE-LANG': xPalqeeLang,
        }
        // figure out how to avoid session call on each request
        if (authorization) {
          const auth = authorization
            ? {
                ...commonHeaders,
                authorization,
              }
            : {}

          return resolve({
            headers: {
              ...headers,
              ...auth,
              ...commonHeaders,
            },
          })
        }

        return resolve({
          headers: {
            ...commonHeaders,
          },
        })
      })
      return promise
    },
  )
}
