import { TypePolicies } from '@apollo/client'
import { hashKeyArgs } from './helpers'

export const dashboard = (): TypePolicies => {
  return {
    NamespaceDashboardMutation: {
      merge: true,
    },
    NamespaceDashboardQuery: {
      merge: true,
      fields: {
        get: {
          keyArgs: hashKeyArgs,
        },
      },
    },
    DashboardUserPermission: {
      keyFields: ['userId', 'dashboardId'],
      merge: true,
    },
  }
}
