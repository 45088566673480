import { hasRoleByReadField, hashKeyArgs } from './helpers'
import { currentCompanyIdVar } from '@features/company/store/current-company'
import { AuthRoleCodeEnum } from '@generated/types.d'
import { audienceUsersSelectedVar } from '@features/audiences/cache/audience-users-search'
import { offsetLimitPagination } from '../helpers/pagination'
import { setFullNameType } from '../lib'
import { TypePolicies } from '@apollo/client'

export const user = (): TypePolicies => {
  return {
    NamespaceUserQuery: {
      merge: true,
      fields: {
        get: offsetLimitPagination({
          field: 'users',
          useOffsetLimitRead: true,
          keyArgs: ({ input }) => {
            const { filter, exclude, search } = input ?? {}
            const inputArg = {
              input: {
                filter,
                // only add them if existing from input
                // this allows us to not require this
                // when manipulating cache
                ...(search ? { search } : {}),
                ...(exclude ? { exclude } : {}),
              },
            }
            return hashKeyArgs(inputArg)
          },
        }),
      },
    },
    UserRole: {
      keyFields: ['companyId', 'userId', 'code'],
    },
    User: {
      fields: {
        audiences: {
          merge: (_, incoming = []) => {
            return incoming
          },
        },
        positions: {
          merge: (_, incoming = []) => {
            return incoming
          },
          keyArgs: hashKeyArgs,
        },
        isPalqeeManager: {
          read: (_, { readField }) => {
            return hasRoleByReadField(readField, AuthRoleCodeEnum.PalqeeManager)
          },
        },
        // this appears to only really work for logged in user
        isAdmin: {
          read: (_, { readField }) => {
            const currentCompanyId = currentCompanyIdVar()
            return hasRoleByReadField(
              readField,
              AuthRoleCodeEnum.Administrator,
              currentCompanyId,
            )
          },
        },
        isRegistrationComplete: {
          read: (_, { readField }) => {
            const currentCompanyId = currentCompanyIdVar()
            const hasAdminRole = hasRoleByReadField(
              readField,
              AuthRoleCodeEnum.Administrator,
              currentCompanyId,
            )

            const isMfaEnabled = readField('isMfaEnabled')

            if (hasAdminRole) return isMfaEnabled

            return (
              readField('passwordLastChangeAt') &&
              (isMfaEnabled || !!readField('mfaConfirmed'))
            )
          },
        },
        fullName: setFullNameType(),
        isSelected: {
          read: (_, { readField }) => {
            const selectedUsers = audienceUsersSelectedVar()
            return selectedUsers.some(
              (u) => u.id === readField('id') && u.isSelected,
            )
          },
        },
      },
    },
  }
}
