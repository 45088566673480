import { Company } from '@generated/types.d'

export type CurrentCompanyState = {
  company: Partial<Company> | null
  companyLogo: string | null
}

// actions
export enum CurrentCompanyActionType {
  UPDATE_CURRENT_COMPANY = 'UPDATE_CURRENT_COMPANY',
  UPDATE_CURRENT_COMPANY_LOGO = 'UPDATE_CURRENT_COMPANY_LOGO',
  RESET_CURRENT_COMPANY = 'RESET_CURRENT_COMPANY',
}

export type UpdateCurrentCompanyAction = {
  type: CurrentCompanyActionType.UPDATE_CURRENT_COMPANY
  payload: {
    company: Partial<Company>
  }
}

export type UpdateCurrentCompanyLogoAction = {
  type: CurrentCompanyActionType.UPDATE_CURRENT_COMPANY_LOGO
  payload: {
    dataUrl: string
  }
}

export type ResetCurrentCompanyAction = {
  type: CurrentCompanyActionType.RESET_CURRENT_COMPANY
}

export type CurrentCompanyAction =
  | UpdateCurrentCompanyAction
  | ResetCurrentCompanyAction
  | UpdateCurrentCompanyLogoAction
