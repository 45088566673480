import { useFloatingUiContext } from '@palqee/utils'
import { PopperContentProps } from './types'
import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { arrowLeftPx, arrowTopPx, popperContentArrow } from './popper.css.ts'
import { forwardRef } from 'react'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { Overlay } from './overlay.tsx'

const PopperContent = forwardRef<HTMLDivElement, PopperContentProps>(
  (props, forwardedRef) => {
    const {
      useModal,
      useOverlay,
      usePortal = true,
      useArrow,
      ...restProps
    } = props
    const { context: floatingContext, ...context } = useFloatingUiContext()
    const ref = useMergeRefs([context.refs.setFloating, forwardedRef])

    const { arrowRef, placement, middlewareData, strategy, x, y } = context
    const { x: arrowX, y: arrowY } = middlewareData?.arrow ?? {}

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    const setArrowPx = (arrow: number) =>
      arrow !== null && arrow ? `${arrow}px` : ''

    const { style, floatingStyle, ...floatingProps } =
      context.getFloatingProps(restProps)

    const setFloatingStyle = () => {
      if (useModal) return undefined

      return {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        ...(floatingStyle ?? {}),
        ...(style ?? {}),
        zIndex: 300,
      }
    }

    const renderFocusManager = () => {
      return (
        <FloatingFocusManager context={floatingContext}>
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.03,
            }}
          >
            <div
              ref={ref}
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              style={setFloatingStyle()}
              {...floatingProps}
            >
              {props.children}

              {useArrow && (
                <div
                  className={popperContentArrow}
                  style={{
                    ...assignInlineVars({
                      [arrowLeftPx]: setArrowPx(arrowX),
                      [arrowTopPx]: setArrowPx(arrowY),
                    }),
                    [staticSide]: '-5px',
                  }}
                  id="pq-dialog__content-arrow"
                  ref={arrowRef as any}
                />
              )}
            </div>
          </motion.div>
        </FloatingFocusManager>
      )
    }

    const renderFloating = () => {
      if (useOverlay) {
        return <Overlay>{renderFocusManager()}</Overlay>
      }

      return renderFocusManager()
    }

    const renderPortal = () => {
      if (usePortal) {
        return <FloatingPortal>{renderFloating()}</FloatingPortal>
      }

      return renderFloating()
    }

    return (
      <AnimatePresence>
        {floatingContext.open && renderPortal()}
      </AnimatePresence>
    )
  },
)

export { PopperContent }
