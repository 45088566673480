import * as types from './types'

// @todo integrate with an access control
// wrapper may need ABAC for this and with backend
// https://dev.to/noclat/unify-acl-across-front-end-and-back-end-with-next-js-and-nextauth-js-5ban
// https://www.npmjs.com/package/accesscontrol

export const initialCurrentUserState: types.CurrentUserState = {
  user: null,
  userView: null,
  accessToken: '',
  features: [],
  // @todo make this more robust, look at comment above
  hasProductAccess: false,
}

const handleUpdateCurrentUser = (
  state: types.CurrentUserState,
  action: types.UpdateCurrentUserAction,
): types.CurrentUserState => {
  const userUpdate = {
    ...state.user,
    ...action.payload.user,
  }

  return {
    ...state,
    user: userUpdate,
  }
}

const handleResetCurrentUser = (): types.CurrentUserState => {
  return {
    ...initialCurrentUserState,
  }
}

const CurrentUserReducer = (
  state: types.CurrentUserState = initialCurrentUserState,
  action: types.CurrentUserAction,
) => {
  switch (action.type) {
    case types.CurrentUserActionType.UPDATE_CURRENT_USER:
      return handleUpdateCurrentUser(state, action)

    case types.CurrentUserActionType.RESET_CURRENT_USER:
      return handleResetCurrentUser()

    case types.CurrentUserActionType.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload.accessToken }

    case types.CurrentUserActionType.SET_ACCESS_CONTROL:
      return { ...state, hasProductAccess: action.payload.hasProductAccess }

    case types.CurrentUserActionType.SET_FEATURES:
      return { ...state, features: action.payload.features }

    case types.CurrentUserActionType.SET_USER_VIEW:
      return { ...state, userView: action.payload.userView }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default CurrentUserReducer
