import Posthog from 'posthog-js'
import { PostHog } from 'posthog-js/react'

export const init = (): {
  client: PostHog
} => {
  if (process.env.NEXT_PUBLIC_POSTHOG_ENABLED === 'true') {
    const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY

    if (!POSTHOG_API_KEY) {
      throw new Error(
        'Invalid / Missing environment variable: "NEXT_PUBLIC_POSTHOG_API_KEY"',
      )
    }

    if (typeof window !== 'undefined') {
      console.log('initializing posthog...')
      Posthog.init(POSTHOG_API_KEY, {
        autocapture: false,
      })
      console.log('posthog initialized', Posthog)
    }
  } else {
    console.warn(
      'Posthog is not enabled missing environment variable: NEXT_PUBLIC_POSTHOG_ENABLED',
    )
  }

  return {
    client: Posthog ?? null,
  }
}

export default Posthog
