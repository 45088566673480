import queryString from 'query-string'
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../../../../common/utils/session-storage'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../../common/utils/local-storage'
import { FORM_EVENT } from './event'
import { isBrowser } from '@common/utils/is-browser'

declare global {
  interface Window {
    pqDataLayer: any
    ga: any
  }
}

const searchEngines = [
  ['360', '360.cn'],
  ['alice', 'www.alice.com'],
  ['alice', 'aliceadsl.fr'],
  ['alltheweb', 'www.alltheweb.com'],
  ['altavista', 'www.altavista.com'],
  ['aol', 'www.aol.com'],
  ['ask', 'www.ask.com'],
  ['ask', 'search.aol.fr'],
  ['ask', 'alicesuche.aol.de'],
  ['auone', 'search.auone.jp'],
  ['avg', 'isearch.avg.com'],
  ['babylon', 'search.babylon.com'],
  ['baidu', 'www.baidu.com'],
  ['biglobe', 'biglobe.ne.jp'],
  ['bing', 'www.bing.com'],
  ['centrum.cz', 'search.centrum.cz'],
  ['comcast', 'search.comcast.net'],
  ['conduit', 'search.conduit.com'],
  ['cnn', 'www.cnn.com/SEARCH/'],
  ['daum', 'www.daum.net'],
  ['duckduckgo', 'duckduckgo.com'],
  ['ecosia', 'www.ecosia.org'],
  ['ekolay', 'www.ekolay.net'],
  ['eniro', 'www.eniro.se'],
  ['globo', 'www.globo.com/busca/'],
  ['go.mail.ru', 'go.mail.ru'],
  ['google', 'www.google.*'],
  ['goo.ne', 'goo.ne.jp'],
  ['haosou.com', 'www.haosou.com/s/'],
  ['incredimail', 'search.incredimail.com'],
  ['kvasir', 'www.kvasir.no'],
  ['live', 'www.bing.com'],
  ['lycos', 'www.lycos.com'],
  ['lycos', 'search.lycos.*'],
  ['mamma', 'www.mamma.com'],
  ['msn', 'www.msn.com'],
  ['msn', 'money.msn.com'],
  ['msn', 'local.msn.com'],
  ['mynet', 'www.mynet.com'],
  ['najdi', 'najdi.si'],
  ['naver', 'www.naver.com'],
  ['netscape', 'search.netscape.com'],
  ['onet', 'szukaj.onet.pl'],
  ['ozu', 'www.ozu.es'],
  ['qwant', 'www.qwant.com'],
  ['rakuten', 'rakuten.co.jp'],
  ['rambler', 'rambler.ru'],
  ['search-results', 'search-results.com'],
  ['search.smt.docomo', 'search.smt.docomo.ne.jp'],
  ['sesam', 'sesam.no'],
  ['seznam', 'www.seznam.cz'],
  ['so.com', 'www.so.coms'],
  ['sogou', 'www.sogou.com'],
  ['startsiden', 'www.startsiden.no/sok/'],
  ['szukacz', 'www.szukacz.pl'],
  ['terra', 'buscador.terra.com.br'],
  ['tut.by', 'search.tut.by'],
  ['ukr', 'search.ukr.net'],
  ['virgilio', 'search.virgilio.it'],
  ['voila', 'www.voila.fr'],
  ['wirtulana polska', 'www.wp.pl'],
  ['yahoo', 'www.yahoo.*'],
  ['yahoo', 'yahoo.*'],
  ['yahoo', 'm.yahoo.*'],
  ['yandex', 'www.yandex.*'],
  ['yandex', 'yandex.*'],
  ['yam', 'www.yam.com'],
]

// https://stackoverflow.com/questions/2400935/browser-detection-in-javascript
const getBrowserName = () => {
  const ua = navigator.userAgent
  let tem =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return `IE ${tem[1] || ''}`
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge?)\/(\d+)/)
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
        .replace('Edg ', 'Edge ')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ')
}

export const getAllUtmValues = () => {
  const session =
    typeof window !== 'undefined' ? window.pqDataLayer.session || {} : {}
  return Object.keys(session)
    .filter((key) => key.startsWith('utm_'))
    .reduce((obj, key) => {
      obj[key] = session[key]
      return obj
    }, {})
}

export const getUtmValue = (utmName) => {
  const session =
    typeof window !== 'undefined' ? window.pqDataLayer.session || {} : {}
  return session[utmName] || ''
}

const getSearchEngineName = (url) => {
  const searchEngine = searchEngines.find(([, regexStr]) => {
    // convert "www.yam.com" => "www\.yam\.com" so our regex will work right
    const regex = new RegExp(regexStr.replace(/\./gi, '\\.'))
    return regex.exec(url)
  })

  if (searchEngine) {
    const [name] = searchEngine
    return name
  }

  return null
}

// the url might be corrupted
const getURLInfo = (url) => {
  try {
    return new URL(url)
  } catch (e) {
    return {}
  }
}

export const sendFormEvent = (form, detail) => {
  form.dispatchEvent(new CustomEvent(FORM_EVENT, { detail }))
}

export const getUtmValues = (queryParams, referrer, googleClickId) => {
  const getUtmVal = (attr) =>
    queryParams[attr] || sessionStorage.getItem(`session.${attr}`)

  let utm_source
  let utm_medium
  let utm_campaign
  let utm_content
  let utm_term

  if (googleClickId) {
    utm_source = 'google'
    utm_medium = 'cpc'
  }

  utm_source = utm_source || getUtmVal('utm_source')
  if (utm_source) {
    utm_medium = utm_medium || getUtmVal('utm_medium')
    utm_campaign = getUtmVal('utm_campaign')
    utm_content = getUtmVal('utm_content')
    utm_term = getUtmVal('utm_term')
  }

  if (!utm_source && referrer) {
    const { hostname, pathname } = getURLInfo(referrer) as any
    const searchEngine = hostname && getSearchEngineName(referrer)
    if (searchEngine) {
      utm_source = searchEngine
      utm_medium = 'organic'
      utm_term = getUtmVal('utm_term')
    } else if (hostname) {
      // only count referrals with proper urls
      utm_source = hostname
      utm_medium = 'referral'
      utm_content = pathname
    }
  }

  if (!utm_source) {
    utm_source = '(direct)'
    utm_medium = '(none)'
    utm_content = getUtmVal('utm_content')
  }

  // Add extra UTM values
  const extraAttrs = [
    'utm_visual',
    'utm_account',
    'utm_agent',
    'utm_format',
    'utm_offer',
  ].reduce((obj, attr) => {
    obj[attr] = getUtmVal(attr)
    return obj
  }, {})

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    ...extraAttrs,
  }
}

// variables from google tag manager
// ref https://support.google.com/analytics/answer/7584446?hl=en
export const setGAVariables = () => {
  if (!window.ga) return
  const tracker = window.ga.getAll()[0]
  window.pqDataLayer = window.pqDataLayer || { session: {} }
  const { pqDataLayer } = window

  pqDataLayer.platform = pqDataLayer.platform || {}
  pqDataLayer.platform.google_track_id = tracker.get('trackingId')

  pqDataLayer.visitor = pqDataLayer.visitor || {}
  pqDataLayer.visitor.google_user_id = tracker.get('userId')
  pqDataLayer.visitor.google_client_id = tracker.get('clientId')
}

export const dataLayer = () => {
  let pqDataLayer = (typeof window !== 'undefined' && window.pqDataLayer) || {}
  pqDataLayer = {
    ...pqDataLayer,
    page: pqDataLayer.page || {},
    visitor: {},
    session: {},
    platform: {},
    events: [],
  }

  if (isBrowser()) {
    const referrer = document.referrer || ''

    // eslint-disable-next-line no-restricted-globals
    const { href, origin, pathname, hostname, search } = location
    const query = queryString.parse(search)

    pqDataLayer.page = {
      ...pqDataLayer.page,
      url: `${origin}${pathname}`,
      url_full: href,
      path: pathname,
      referring_url: referrer.split('?')[0],
      referring_url_full: referrer,
      referring_domain:
        Array.isArray(referrer.match(/:\/\/(.[^/]+)/)) &&
        referrer.match(/:\/\/(.[^/]+)/)[1],
      tracking_name:
        pathname === '/'
          ? 'home'
          : pathname.replace(/\//g, ' ').trim().replace(/ /g, ':'),
    }

    try {
      const { userAgent, platform } = navigator
      const deviceType =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent,
        )
          ? 'Mobile'
          : 'Desktop'

      pqDataLayer.visitor = {
        user_agent: userAgent,
        browser: getBrowserName(),
        operating_system: platform,
        device_type: deviceType,
      }
      Object.keys(pqDataLayer.visitor).forEach((attr) => {
        setSessionStorageItem(`visitor.${attr}`, pqDataLayer.visitor[attr])
      })

      // google_click_id is special: its param name is gclid, but in session, its name is google_click_id
      const googleClickId =
        query.gclid || getSessionStorageItem('session.google_click_id')
      pqDataLayer.session.google_click_id = googleClickId
      if (query.gclid) {
        setSessionStorageItem('session.google_click_id', googleClickId)
      }

      // Add utm values
      const utmValues = getUtmValues(query, referrer, googleClickId)
      Object.keys(utmValues).forEach((key) => {
        pqDataLayer.session[key] = utmValues[key]
        if (utmValues[key]) {
          setSessionStorageItem(`session.${key}`, utmValues[key])
        }
      })
      // platform attrs
      pqDataLayer.platform.domain = hostname

      const isProduction =
        process.env.NODE_ENV === 'production' ||
        String(process.env.NODE_ENV) === 'master'
      pqDataLayer.platform.environment =
        getLocalStorageItem('platform.environment') ||
        (isProduction ? 'production' : 'development')

      if (!getLocalStorageItem('platform.environment')) {
        setLocalStorageItem(
          'platform.environment',
          pqDataLayer.platform.environment,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  return pqDataLayer
}
