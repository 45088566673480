import {
  PropsWithChildren,
  cloneElement,
  forwardRef,
  isValidElement,
} from 'react'
import { PopperTriggerProps } from './types'
import { useFloatingUiContext } from '@palqee/utils'
import { useMergeRefs } from '@floating-ui/react'

const PopperTrigger = forwardRef<
  Element,
  PropsWithChildren<PopperTriggerProps>
>((props, forardedRef) => {
  const { children, style, ...restProps } = props

  const context = useFloatingUiContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([
    context.refs.setReference,
    forardedRef,
    childrenRef,
  ])

  const renderTrigger = () => {
    if (isValidElement(children)) {
      const { style: childrenStyle, ...restChildrenProps } = children.props
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...restProps,
          ...restChildrenProps,
          style: {
            ...childrenStyle,
            ...style,
          },
          'data-state': context.open ? 'open' : 'closed',
        }),
      )
    }
  }

  return <span>{renderTrigger()}</span>
})

export { PopperTrigger }
