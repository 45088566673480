import App, { AppContext, AppInitialProps } from 'next/app'
import { DEFAULT_DATE_FORMAT, DEFAULT_NUMBER_FORMAT } from '@const/locale'
import { PqIntlState, getMessages } from '@palqee/intl'
import { Cookies } from '@features/core/cookies/types'
import { getCookies } from 'cookies-next'

/**
 * Handles some initial props
 * @todo find a way to not require getInitialProps in app
 *        in order to benefit for Automatic Static Optimization
 *
 * Setting locale and getting messages based on locale
 *
 * @param appContext
 * @returns {AppInitialProps & PqIntlState}
 */
export const GetInitialProps = async (
  appContext: AppContext,
): Promise<AppInitialProps & PqIntlState> => {
  const { ctx } = appContext

  const readonlyCookies: Cookies = getCookies({ req: ctx?.req, res: ctx?.res })

  // load messages / string based on locale
  // @note in new app directory this can be done
  // in RSC (react server component)
  const [, messagePromise] = getMessages(ctx.locale)
  const [messages, appProps] = await Promise.all([
    messagePromise,
    App.getInitialProps(appContext),
  ])

  const userAgent = ctx?.req?.headers['user-agent'] || null

  return {
    ...(appProps as any),
    userAgent,
    dateFormat: readonlyCookies['dateFormat'] || DEFAULT_DATE_FORMAT,
    numberFormat: readonlyCookies['numberFormat'] || DEFAULT_NUMBER_FORMAT,
    messages: messages.default,
  }
}
