import { GeneralSearchMainEntitiesPayload } from '@generated/types.d'
import { TypePolicies } from '@palqee/apollo-client'
import { offsetLimitPagination } from '../helpers/pagination'

export const general = (): TypePolicies => {
  return {
    NamespaceGeneralQuery: {
      merge: true,
      fields: {
        searchMainEntities: offsetLimitPagination({
          field: 'results',
          keyArgs: [
            'input',
            [
              'filter',
              ['companyIds', 'palqeeModuleCodes'],
              'search',
              ['pattern'],
            ],
          ],
          paginationInfoHandler: (merged: GeneralSearchMainEntitiesPayload) => {
            return {
              ...merged?.paginationInfo,
              limit: merged?.results?.length,
            }
          },
        }),
      },
    },
  }
}
