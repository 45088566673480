import { LOGIN } from '@common/components/consts'
import {
  ExceptionCode,
  GraphQlError,
  getGraphQLErrorCodeFromError,
} from '@common/utils/errors'
import { fetchWithRetry } from '@palqee/utils'
import { captureException } from '@sentry/nextjs'
import { GetServerSidePropsContext } from 'next'

export function createCallbackUrl(req) {
  // Assuming req.headers.host gives the host name, and req.url gives the current route
  const currentRoute = req.url

  let callback = ''

  // check if more than just slash
  if (currentRoute.length > 1 && !currentRoute.includes('callbackUrl')) {
    // remove next-auth error from url

    // URL encode the current route
    const encodedCurrentRoute = encodeURIComponent(currentRoute)

    if (
      !encodedCurrentRoute.includes('_next/data') &&
      !encodedCurrentRoute.includes('error=')
    )
      callback = `?callbackUrl=${encodedCurrentRoute}`
  }

  // Define your callback url
  const callbackUrl = `${LOGIN}${callback}`

  return callbackUrl
}

export const signOutRequest = async () => {
  try {
    // signout
    await fetchWithRetry(`${process.env.NEXTAUTH_URL}/api/auth/signout`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: await fetchWithRetry(
        `${process.env.NEXTAUTH_URL}/api/auth/csrf`,
      ).then((rs) => rs.text()),
    })
  } catch (error) {
    captureException(error)
    console.error(error)
  }
}

export const signOut = async (ctx: GetServerSidePropsContext) => {
  await signOutRequest()

  return {
    redirect: {
      destination: createCallbackUrl(ctx?.req),
      permanent: false,
    },
  }
}

export const unauthHandler = (error: GraphQlError[]) => {
  const errorCode = getGraphQLErrorCodeFromError(error)

  // return to login page
  if (errorCode === ExceptionCode.AuthNotAuthenticated) {
    return errorCode
  }

  return null
}
