const cache = {}

export const getSessionStorageItem = (key) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  try {
    return sessionStorage.getItem(key) || cache[key]
  } catch (error) {
    console.warn('get sessionStorage not supported')
  }
  return undefined
}

export const setSessionStorageItem = (key, value) => {
  try {
    cache[key] = value
    sessionStorage.setItem(key, value)
  } catch (error) {
    console.warn('set sessionStorage not supported')
  }
}
