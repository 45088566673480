import React from 'react'

// src
import { CurrentCompanyState } from './types'
import CurrentCompanyReducer, { initialCurrentCompanyState } from './reducer'

type CurrentCompanyDispatch = (action: any) => void
type CurrentCompanyProviderProps = { children: React.ReactNode }

const CurrentCompanyStateContext =
  React.createContext<CurrentCompanyState | null>(null)
const CurrentCompanyDispatchContext =
  React.createContext<CurrentCompanyDispatch | null>(null)

const CurrentCompanyProvider = (props: CurrentCompanyProviderProps) => {
  const { children } = props
  const [state, dispatch] = React.useReducer(
    CurrentCompanyReducer,
    initialCurrentCompanyState,
  )

  return (
    <CurrentCompanyStateContext.Provider value={state}>
      <CurrentCompanyDispatchContext.Provider value={dispatch}>
        {children}
      </CurrentCompanyDispatchContext.Provider>
    </CurrentCompanyStateContext.Provider>
  )
}

const useCurrentCompanyState = () => {
  const ctx = React.useContext(CurrentCompanyStateContext)
  if (ctx === null) {
    throw new Error(
      'useCurrentCompanyState must be used within a CurrentCompanyProvider',
    )
  }

  return ctx
}

const useCurrentCompanyDispatch = () => {
  const ctx = React.useContext(CurrentCompanyDispatchContext)
  if (ctx === null) {
    throw new Error(
      'useCurrentCompanyDispatch must be used within a CurrentCompanyProvider',
    )
  }

  return ctx
}

const useCurrentCompany = (): [CurrentCompanyState, CurrentCompanyDispatch] => {
  const state = useCurrentCompanyState()
  const dispatch = useCurrentCompanyDispatch()

  return [state, dispatch]
}

export { CurrentCompanyProvider, useCurrentCompany }
