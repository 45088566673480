import { PropsWithChildren, forwardRef } from 'react'
import classNames from 'classnames'
import { DialogHeading } from './heading'
import { DialogBody } from './body'
import { DialogContent } from './content'
import { DialogProps } from './types'
import { DialogTrigger } from './trigger'
import { DialogFooter } from './footer'
import { Popper } from '/@popper'

const Dialog = forwardRef<HTMLDivElement, PropsWithChildren<DialogProps>>(
  (props, forwardedRef) => {
    const { children, className, ...options } = props

    const classes = classNames('pq-dialog', className)

    return (
      <Popper role="dialog" {...options}>
        <div ref={forwardedRef} className={classes}>
          {children}
        </div>
      </Popper>
    )
  },
)

Dialog.displayName = 'Dialog'

const DialogNamespace = Object.assign(Dialog, {
  Trigger: DialogTrigger,
  Heading: DialogHeading,
  Body: DialogBody,
  Content: DialogContent,
  Footer: DialogFooter,
})

export { DialogNamespace as Dialog }
