import { SentryLink } from 'apollo-link-sentry'

export const ApolloLinkSentry = ({ uri }) => {
  return new SentryLink({
    uri,
    attachBreadcrumbs: {
      includeError: true,
    },
  })
}
