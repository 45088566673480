import '@scripts/wdyr'

import * as styleVars from '@common/styles/constants'

import { ReactNode, useEffect } from 'react'
import { ThemeProvider, css } from '@emotion/react'
import { addBreadcrumb, configureScope } from '@sentry/nextjs'
import { PqIntlProvider } from '@palqee/intl'
import { CurrentCompanyProvider } from '@contexts/current-company'
import { CurrentUserProvider } from '@contexts/current-user'
import GlobalStyles from '@common/styles/global-styles'
import { PostHogProvider, init } from '@palqee/analytics'
import { SessionProvider } from 'next-auth/react'
import { TAppBootstrapProps } from './types'
import dynamic from 'next/dynamic'
import { getComponentName } from './get-component-name'
import { parse } from 'next-useragent'
import { theme } from '@common/styles/theme'
import { useApollo } from '@palqee/apollo-client'
import { typePolicies } from '@features/core/graphql/type-policies'
import { onErrorHandler } from '@features/core/graphql/graphql-errors'
import { ApolloLinkSentry } from '@features/core/sentry/lib'
import generatedIntrospection from '@features/core/graphql/fragment-matcher'
import { NiceModalProvider } from '@palqee/ui'
import { GoogleTagManager } from '@next/third-parties/google'
import { dataLayer } from '@features/core/google/gtm/tracking'
// import css file
import '@palqee/ui/dist/index.css'
import { MobileDevicesApp } from './mobile-devices'
import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from '@features/auth/store/provider'
import { currentCompanyIdVar } from '@features/company/store/current-company'

const Toast = dynamic(() => import('@common/components/toast'))
const Progress = dynamic(() => import('@common/components/progress'))

const category = 'app/bootstrap/AppBootstrap'
// @todo add logger

/**
 * Used for persisting layouts so default page return
 */
const Noop = (page: ReactNode) => page

/**
 * Boostrap page and renders it
 *
 * All behaviors defined here are applied across entier application
 *
 * @note need to have some CurrentContext Provider that will
 * initiate both the user context and important fields required right away
 * and same for the company and any other related fields or entities
 * can be taken in fragments in the children components
 *
 * @param props
 * @returns {JSX.Element}
 */
const AppBootstrap = (props: TAppBootstrapProps): JSX.Element => {
  const {
    Component,
    pageProps,
    numberFormat,
    dateFormat,
    messages,
    router,
    userAgent,
  } = props

  // for now adding this at root to set current company id
  // as soon as possible but this should be moved to CurrentContext
  // currently being used in type policies
  useEffect(() => {
    currentCompanyIdVar(
      router?.query?.companyId ? String(router?.query?.companyId) : '',
    )
  }, [router?.query?.companyId, currentCompanyIdVar])

  const getLayout = Component.getLayout || Noop
  const pageComponentName = getComponentName(Component)

  // initialize apollo client
  const client = useApollo(pageProps, {
    inMemoryCacheConfig: {
      possibleTypes: generatedIntrospection.possibleTypes,
      ...typePolicies(),
    },
    apolloClientOptions: {
      connectToDevTools:
        process.env.NEXT_PUBLIC_BUILD_ENV !== 'production' || false,
    },
    apolloClientLink: [
      onErrorHandler,
      ApolloLinkSentry({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
      }),
    ],
    headers: {
      lang: router?.locale,
      authorization: pageProps?.session?.accessToken,
    },
  })

  addBreadcrumb({
    category,
    message: `Rendering ${category}`,
    level: 'info',
  })

  const {
    asPath,
    basePath,
    defaultLocale,
    locales,
    locale,
    pathname,
    query,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // ...restRouter // Other router props aren't interesting to track and are being ignored
  } = router

  configureScope((scope): void => {
    scope.setContext('_app.props', {
      pageComponentName,
      pageProps,
      dateFormat,
      numberFormat,
    })

    scope.setContext('_app.router', {
      asPath,
      basePath,
      defaultLocale,
      locale,
      locales,
      pathname,
      query,
    })

    scope.setContext('build', {
      buildTime: process.env.NEXT_PUBLIC_APP_BUILD_TIME,
      buildTimeISO: new Date(
        process.env.NEXT_PUBLIC_APP_BUILD_TIME ?? '',
      ).toISOString(),
    })
  })

  let ua
  if (!!userAgent) {
    ua = parse(userAgent)
  } else if (typeof window !== 'undefined' && !!window.navigator) {
    ua = parse(window.navigator.userAgent)
  }

  // @todo improve to control what pages should not show mobile page
  // pathname check added related to:
  // https://palqee.atlassian.net/browse/PL-2387
  if (!!ua && ua.isMobile && !router.pathname.includes('consent/verify')) {
    return <MobileDevicesApp {...props} />
  }

  const { client: posthogClient } = init()

  return (
    <>
      <PqIntlProvider
        initialState={{
          locale,
          dateFormat,
          numberFormat,
          messages,
          supportedLocales: locales,
        }}
      >
        <PostHogProvider client={posthogClient}>
          <SessionProvider session={pageProps.session}>
            <AuthProvider>
              <ApolloProvider client={client}>
                <NiceModalProvider>
                  <CurrentUserProvider>
                    <CurrentCompanyProvider>
                      <ThemeProvider theme={theme}>
                        <GlobalStyles />
                        <Progress color={styleVars.primaryRed} height={3} />
                        {getLayout(
                          <Component
                            {...{
                              ...pageProps,
                              messages,
                            }}
                          />,
                        )}
                        <Toast
                          position="bottom-left"
                          containerCssProps={css`
                            max-width: 380px;
                            width: 25%;
                            height: 90px;
                            z-index: 9999;
                          `}
                        />
                      </ThemeProvider>
                    </CurrentCompanyProvider>
                  </CurrentUserProvider>
                </NiceModalProvider>
              </ApolloProvider>
            </AuthProvider>
          </SessionProvider>
        </PostHogProvider>
      </PqIntlProvider>
      <GoogleTagManager
        dataLayer={dataLayer()}
        gtmId={process.env.NEXT_PUBLIC_GTM_ID}
        preview={process.env.NEXT_PUBLIC_GTM_PREVIEW}
        auth={process.env.NEXT_PUBLIC_GTM_AUTH}
      />
    </>
  )
}

export default AppBootstrap
