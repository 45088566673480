import { DatamapResponse, SurveyBuilderQuestionMode } from '@generated/types.d'

export enum Mode {
  onchange = 'onchange',
  onsubmit = 'onsubmit',
}

export type SubmitMode = keyof typeof Mode

export interface IExtractQuestionOptions {
  displayMode?: SurveyBuilderQuestionMode
  submitMode?: SubmitMode
  isGuided?: boolean
  useQuestionId?: boolean
  setQuestionsAsFreeText?: boolean
  filterQuestionsBySubcategoryId?: string
  filterResponsesByUserId?: string
  datamapResponses?: DatamapResponse[]
  onlySummarizeResponsesByUserIds?: string[]
  onSummaryClickResponse?: (a, questionEventPayload) => void
}

export enum QuestionTypeCode {
  BLANK = 'BLANK',
  TEXT = 'TEXT',
  LIST = 'LIST',
  DROPDOWN = 'DROPDOWN_SINGLE',
  MATRIX = 'MATRIX',
  CHOICE_MULTIPLE = 'CHOICE_MULTIPLE',
  CHOICE_SINGLE = 'CHOICE_SINGLE',
  RATING = 'RATING',
  ATTACHMENT_GENERIC = 'ATTACHMENT_GENERIC',
  DROPDOWN_MULTIPLE = 'DROPDOWN_MULTIPLE',
}
