/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */

// @todo delete when merged to apollo client
import { ApolloCache, InMemoryCache } from '@palqee/apollo-client'

import { Slot } from '@wry/context'
import { dep } from 'optimism'

function isString(value: any) {
  return Object.prototype.toString.call(value) === '[object String]'
}

export interface ReactiveVar<T> {
  (newValue?: T): T
  onNextChange(listener: ReactiveListener<T>): () => void
}

export type ReactiveListener<T> = (value: T) => any

const varDep = dep<ReactiveVar<any>>()

// Contextual Slot that acquires its value when custom read functions are
// called in Policies#readField.
export const cacheSlot = new Slot<ApolloCache<any>>()

// A listener function could in theory cause another listener to be added
// to the set while we're iterating over it, so it's important to commit
// to the original elements of the set before we begin iterating. See
// iterateObserversSafely for another example of this pattern.
function consumeAndIterate<T>(set: Set<T>, callback: (item: T) => any) {
  const items: T[] = []
  set.forEach((item) => items.push(item))
  set.clear()
  items.forEach(callback)
}

// makeVar overload signatures
export function makeVar<T>(value: T): ReactiveVar<T>
export function makeVar<T>(
  value: T,
  config: PersistenceConfig,
): [ReactiveVar<T>, () => Promise<void>]

export function makeVar<T>(value: T, config?: PersistenceConfig) {
  const caches = new Set<ApolloCache<any>>()
  const listeners = new Set<ReactiveListener<T>>()

  const rv: ReactiveVar<T> = function (newValue) {
    if (arguments.length > 0) {
      if (value !== newValue) {
        value = newValue!
        // First, invalidate any fields with custom read functions that
        // consumed this variable, so query results involving those fields
        // will be recomputed the next time we read them.
        varDep.dirty(rv)
        // Next, broadcast changes to any caches that have previously read
        // from this variable.
        caches.forEach(broadcast)
        // Finally, notify any listeners added via rv.onNextChange.
        consumeAndIterate(listeners, (listener) => listener(value))
        // Run persistence options
        try {
          // eslint-disable-next-line no-unused-expressions
          config?.storage.setItem(
            config.storageKey,
            cleanValueToSetStorage(value),
          )
        } catch {
          // pass
        }
      }
    } else {
      // When reading from the variable, obtain the current cache from
      // context via cacheSlot. This isn't entirely foolproof, but it's
      // the same system that powers varDep.
      const cache = cacheSlot.getValue()
      if (cache) caches.add(cache)
      varDep(rv)
    }

    return value
  }

  rv.onNextChange = (listener) => {
    listeners.add(listener)
    return () => {
      listeners.delete(listener)
    }
  }

  if (!config) return rv

  const restore = async () => {
    // Set reactiveVar to previous value from storage,
    // if there is no previous value, do nothing.
    try {
      const previousValue = await config.storage.getItem(config.storageKey)
      if (previousValue) {
        rv(isString(value) ? previousValue : JSON.parse(previousValue))
      }
    } catch (err) {
      // pass
    }
  }

  return [rv, restore]
}

type Broadcastable = ApolloCache<any> & {
  // This method is protected in InMemoryCache, which we are ignoring, but
  // we still want some semblance of type safety when we call it.
  broadcastWatches?: InMemoryCache['broadcastWatches']
}

function broadcast(cache: Broadcastable) {
  if (cache.broadcastWatches) {
    cache.broadcastWatches()
  }
}

// Persistence utils

export interface PersistentStorage {
  getItem: (key: string) => Promise<void | any>
  setItem: (key: string, data: string) => Promise<void | any>
}

export type PersistenceConfig = {
  storage: PersistentStorage
  storageKey: string
}

function cleanValueToSetStorage(value: any): string {
  return isString(value) ? value : JSON.stringify(value)
}
