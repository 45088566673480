export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

/**
 * This states if Sentry should be enabled and bundled within our App
 *
 * We enable sentry by default if we're om development mode or deployed
 * on Vercel (either production or preview branches)
 */
export const SENTRY_ENABLE =
  process.env.SENTRY_ENABLED === 'true' ||
  process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true'

/**
 * This configures which Sentry features to tree-shake/remove from the Sentry bundle
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/tree-shaking/
 */
export const SENTRY_EXTENSIONS = {
  __SENTRY_DEBUG__: false,
  __SENTRY_TRACING__: false,
  __RRWEB_EXCLUDE_IFRAME__: true,
  __RRWEB_EXCLUDE_SHADOW_DOM__: true,
  __SENTRY_EXCLUDE_REPLAY_WORKER__: true,
}

/**
 * This configures the sampling rate for Sentry
 *
 * We always want to capture 100% on Development Branches
 * and not when it's on Production Mode (nodejs.org)
 */
export const SENTRY_CAPTURE_RATE =
  SENTRY_ENABLE && process.env.BUILD_ENV !== 'master' ? 1.0 : 0.01
