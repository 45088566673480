import { PropsWithChildren, createElement } from 'react'
import { ICardHeaderTitle } from './types'
import classNames from 'classnames'

const CardHeaderTitle = (props: PropsWithChildren<ICardHeaderTitle>) => {
  const { children, as, className, ...rest } = props

  const classes = classNames('pq-card__header-title', className)

  return createElement(
    as,
    {
      className: classes,
      ...rest,
    },
    children,
  )
}

export { CardHeaderTitle }
