import { create as createModal, useModal } from '@ebay/nice-modal-react'
import classNames from 'classnames'
import { MouseEvent, useState } from 'react'
import { Dialog } from '.'
import { Button } from '/@button'
import { Spinner } from '/@spinner'
import { DialogManagerProps } from './types'
import { dialogManagerSubmitBtn } from './dialog.css.ts'

const DialogManager = createModal((props: DialogManagerProps) => {
  const {
    id,
    className,
    children,
    cancelBtn,
    confirmBtn,
    afterClickConfirm,
    payload,
    hideOnConfirm,
    hideControls,
    dialogContentCss,
    cardStyle,
    dialogProps,
  } = props

  const [loading, setLoading] = useState(false)

  const modal = useModal(id)

  const setOpen = (p) => {
    if (p) {
      modal.show()
    } else {
      modal.remove()
    }
  }

  const classes = classNames('pq-dialog__manager', className)

  const onClickHandler = async (e: MouseEvent<HTMLElement>) => {
    setLoading(true)

    try {
      if (afterClickConfirm) await afterClickConfirm(e, payload, { modal })

      if (payload) modal.resolve(payload)

      if (hideOnConfirm) modal.remove()
    } catch (error) {
      modal.reject(error)
    }

    setLoading(false)
  }

  const renderCancelBtn = () => {
    const { hide, title, onCancel, ...cancelBtnRest } = cancelBtn ?? {}

    if (hide || !cancelBtn) return null

    return (
      <Button
        variant="secondary-blue"
        onClick={async (e) => {
          if (onCancel) {
            await onCancel(e)
          }

          modal.remove()
        }}
        title={title}
        {...cancelBtnRest}
      >
        {title}
      </Button>
    )
  }

  const renderSubmitBtn = () => {
    const {
      hide,
      title,
      onConfirm,
      disabled,
      className: btnClassName,
      ...confirmBtnRest
    } = confirmBtn ?? {}

    if (hide) return null

    const btnClasses = classNames(btnClassName, dialogManagerSubmitBtn)

    return (
      <Button
        variant="primary-blue"
        className={btnClasses}
        onClick={async (e) => {
          if (onConfirm) {
            await onConfirm(e)
          }

          await onClickHandler(e)
        }}
        disabled={loading || disabled}
        title={title}
        {...confirmBtnRest}
      >
        {title}
        {loading && (
          <Spinner
            color="#ffffff"
            style={{ marginLeft: '1em' }}
            height={11}
            width={11}
          />
        )}
      </Button>
    )
  }

  const renderControls = () => {
    if (hideControls) return null

    return (
      <>
        {renderCancelBtn()}
        {renderSubmitBtn()}
      </>
    )
  }

  return (
    <Dialog
      isOpen={modal.visible}
      onOpenChange={setOpen}
      open={modal.visible}
      className={classes}
      {...dialogProps}
    >
      <Dialog.Content cardStyle={cardStyle} css={dialogContentCss}>
        {children}
        <Dialog.Footer>{renderControls()}</Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
})

export { DialogManager }
