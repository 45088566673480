import { FieldFunctionOptions, FieldPolicy } from '@palqee/apollo-client'
import {
  IPaginateResultsProps,
  IPagination,
  IReadHelperProps,
  TPaginationInfoHandler,
} from './types'

import { findAllByKey } from '../type-policies/helpers'
import isEmpty from 'lodash/isEmpty'

const mergePaginateResults = <T>(props: IPaginateResultsProps<T>) => {
  const { args, incomingData, existingData } = props

  const data = existingData

  // find pagination
  const pagination = findAllByKey(args, 'pagination')
  // Assume an offset of 0 if args.offset omitted.
  const { offset = 0 } = pagination?.[0] || {}

  for (let i = 0; i < incomingData.length; ++i) {
    data[offset + i] = incomingData[i]
  }

  return data
}

const readPaginateResults = <T>(props: IReadHelperProps<T>) => {
  const { args, existingData } = props

  // find pagination
  const pagination = findAllByKey(args, 'pagination')

  const { offset, limit } = pagination?.[0] || {}

  let data = existingData

  // if offset is not undefined then assume
  // we want paged results
  if (offset >= 0) {
    data = data?.slice(offset, offset + limit)
  }

  return data
}

const offsetLimitMerge =
  <T>(field: string, paginationInfoHandler: TPaginationInfoHandler<T>) =>
  (
    existing: Readonly<T>,
    incoming: Readonly<T>,
    options: FieldFunctionOptions<T>,
  ) => {
    const { args } = options

    const existingData = field ? existing?.[field] : existing
    const incomingData = field ? incoming[field] : incoming

    const existingDataSliced = existing ? existingData.slice(0) : []

    let merged = existingDataSliced

    if (args) {
      merged = mergePaginateResults<T>({
        args,
        incomingData,
        existingData: existingDataSliced,
      })
    } else {
      // It's unusual (probably a mistake) for a paginated field not
      // to receive any arguments, so you might prefer to throw an
      // exception here, instead of recovering by appending incoming
      // onto the existing array.
      merged = [...merged, ...incomingData]
    }

    let mergedResult = merged

    if (field) {
      mergedResult = {
        // if incoming data is empty use existing obj
        ...(incoming?.[field].length === 0 ? existing : incoming),
        [field]: merged,
      }
    }

    return {
      ...mergedResult,
      paginationInfo: paginationInfoHandler
        ? paginationInfoHandler(mergedResult)
        : mergedResult?.paginationInfo ?? null,
    }
  }

export const offsetLimitRead =
  <T>(field: string) =>
  (existing: Readonly<T>, options: FieldFunctionOptions) => {
    const { args } = options

    const existingData = field ? existing?.[field] : existing

    if (args) {
      if (!isEmpty(existingData)) {
        const data = readPaginateResults<T>({ args, existingData })

        return {
          ...existing,
          [field]: data,
        }
      }
    }

    return existing
  }

// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export const offsetLimitPagination = <T>(
  props?: IPagination<T>,
): FieldPolicy<T> => {
  const {
    keyArgs = false,
    field,
    paginationInfoHandler,
    useOffsetLimitRead = false,
    ...rest
  } = props ?? {}
  return {
    keyArgs,
    merge: offsetLimitMerge<T>(field, paginationInfoHandler),
    read: useOffsetLimitRead ? offsetLimitRead<T>(field) : undefined,
    ...rest,
  }
}
