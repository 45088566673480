import classNames from 'classnames'
import { ISpinnerProps } from './types'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { vars } from '/@theme/theme.css'
import { colorVar, heightVar, spinner, widthVar } from './spinner.css.ts'

const Spinner = (props: ISpinnerProps) => {
  const { className, width, height, color, style, ...rest } = props

  const classes = classNames('pq-spinner', className, spinner)

  // Utility function to get nested color values from vars.color
  const getColorValue = (
    colorKey: string | undefined,
    colorVars: typeof vars.color,
  ) => {
    if (!colorKey) return undefined

    // Split the color key (e.g., "blue.50" => ["blue", "50"])
    const keys = colorKey.split('.')

    // Reduce the keys to access the nested value
    return keys.reduce((acc, key) => acc?.[key], colorVars)
  }

  const resolvedColor = getColorValue(color, vars.color)

  return (
    <span
      data-cy="pq-spinner"
      className={classes}
      style={{
        ...assignInlineVars({
          [widthVar]: `${width ?? 12}px`,
          [heightVar]: `${height ?? 12}px`,
          [colorVar]: `${resolvedColor ?? vars.color.blue['50']}`,
        }),
        ...style,
      }}
      {...rest}
    />
  )
}

export { Spinner }
