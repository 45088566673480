import { TAppBootstrapProps } from './types'
import { PqIntlProvider } from '@palqee/intl'
import { useRouter } from 'next/router'
import GlobalStyles from '@common/styles/global-styles'
import MobilePage from '@common/components/mobile'

/**
 *
 */
const MobileDevicesApp = (props: TAppBootstrapProps) => {
  const { numberFormat, dateFormat, messages } = props

  const router = useRouter()
  const { locales, locale } = router

  return (
    <>
      <PqIntlProvider
        initialState={{
          locale,
          dateFormat,
          numberFormat,
          messages,
          supportedLocales: locales,
        }}
      >
        <GlobalStyles />
        <MobilePage />
      </PqIntlProvider>
    </>
  )
}

export { MobileDevicesApp }
