import classNames from 'classnames'
import { RefObject, forwardRef } from 'react'
import { IButtonProps } from './types'
import { Spinner } from '/@spinner'
import {
  buttonStyle,
  spinnerStyle,
  variants,
  sizeStyles,
  buttonWidth,
} from './button.css.ts'
import { assignInlineVars } from '@vanilla-extract/dynamic'

const Button = forwardRef(
  (props: IButtonProps, ref: RefObject<HTMLButtonElement>) => {
    const {
      children,
      className,
      loading,
      variant,
      size,
      fullWidth = false,
      style,
      ...rest
    } = props

    const btnClasses = classNames(
      'pq-button',
      className,
      buttonStyle,
      variants[variant],
      size ? sizeStyles[size] : sizeStyles.lg,
    )

    return (
      <button
        ref={ref}
        className={btnClasses}
        style={{
          ...style,
          ...assignInlineVars({
            [buttonWidth]: fullWidth ? '100%' : 'auto',
          }),
        }}
        {...rest}
      >
        {children}
        {loading && <Spinner className={spinnerStyle} />}
      </button>
    )
  },
)

Button.displayName = 'Button'

export { Button }
