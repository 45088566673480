import { createContext, PropsWithChildren, useContext } from 'react'
import { IUseFloatingReturn } from '../types'

const FloatingUiContext = createContext<IUseFloatingReturn | null>(null)

const FloatingUiProvider = (props: PropsWithChildren<IUseFloatingReturn>) => {
  const { children, ...options } = props

  return (
    <FloatingUiContext.Provider value={options}>
      {children}
    </FloatingUiContext.Provider>
  )
}

const useFloatingUiContext = () => {
  const context = useContext(FloatingUiContext)

  if (context === null) {
    throw new Error(
      'useFloatingUiContext components must be wrapped in <FloatingUiProvider />',
    )
  }

  return context
}

export { useFloatingUiContext, FloatingUiProvider, FloatingUiContext }
