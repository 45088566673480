import { signOut, useSession } from 'next-auth/react'
import { PropsWithChildren, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { LOGIN } from '@common/components/consts'
import { GraphQLExceptionCode } from '@features/core/graphql/graphql-errors'

/**
 * HOC to handle checking refresh token
 * and refreshing using update if expired
 */
const AuthProvider = (props: PropsWithChildren) => {
  const { children } = props
  const { data: session, update } = useSession()

  const triggerUpdate = useCallback(() => {
    update({
      tokenExpired: true,
      ...session,
    })
  }, [update])

  const triggerRefresh = useCallback(() => {
    // check if remember me is still set
    if (dayjs().isAfter(dayjs.unix(Number(session?.accessTokenExpires)))) {
      triggerUpdate()
    }
  }, [session, triggerUpdate])

  useEffect(() => {
    if (
      !session?.error ||
      session?.error === GraphQLExceptionCode.JwtTokenExpired
    ) {
      triggerRefresh()
    } else if (
      session?.error === GraphQLExceptionCode.RefreshAccessTokenError
    ) {
      signOut({ callbackUrl: LOGIN })
    }
  }, [triggerRefresh, triggerUpdate, session?.error])

  return <>{children}</>
}

export { AuthProvider }
