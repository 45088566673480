import { css } from '@emotion/react'
import styled from '@emotion/styled'
import classNames from 'classnames'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

const CardFooterWrapper = styled.div`
  &.pq-card__footer {
    box-shadow: 0 -2px 24px 0 rgba(223, 231, 255, 0.2);
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
`

const CardFooter = (
  props: PropsWithChildren<ComponentPropsWithoutRef<'div'>>,
) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-card__footer', className)
  return (
    <CardFooterWrapper className={classes} {...rest}>
      <div
        css={css`
          width: 85%;
          margin: 0 auto;
        `}
      >
        {children}
      </div>
    </CardFooterWrapper>
  )
}

export default CardFooter
