import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import { AuthenticatedUserWithPositionsFragmentDoc } from '../../../../core/graphql/user/__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AuthCurrentUserQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID']['input']
}>

export type AuthCurrentUserQuery = {
  __typename: 'Query'
  auth: {
    __typename: 'NamespaceAuthQuery'
    userCurrent: {
      __typename: 'AuthUserCurrentPayload'
      user?: {
        __typename: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        fullName?: string | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        isMfaEnabled?: boolean | null
        email: any
        isAdmin?: boolean | null
        isPalqeeManager?: boolean | null
        isRegistrationComplete?: boolean | null
        status: Types.UserStatusEnum
        language?: Types.LanguageCodeEnum | null
        defaultCompanyId?: string | null
        positions: Array<{
          __typename: 'UserPosition'
          departmentId: string
          positionId: string
          position: {
            __typename: 'Position'
            id: string
            name: string
            companyId: string
          }
          department: {
            __typename: 'Department'
            id: string
            name: string
            companyId: string
          }
        }>
        defaultCompany?: {
          __typename: 'Company'
          id: string
          name?: string | null
          numberOfEmployees: any
          industries: Array<{
            __typename: 'Industry'
            id: string
            name: string
          }>
          address?: {
            __typename: 'Address'
            id: string
            country: { __typename: 'Country'; id: string; name: string }
          } | null
        } | null
        companies: Array<{
          __typename: 'Company'
          id: string
          name?: string | null
          logo?: {
            __typename: 'BinaryFileInfoPublic'
            publicUrl?: string | null
            id: string
            isPublic: boolean
            originalFilename?: string | null
            mimetype: string
            url: string
          } | null
          currentSubscriptionTier?: {
            __typename: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }>
        roles: Array<{
          __typename: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: Types.AuthRoleCodeEnum
        }>
        tenant: {
          __typename: 'Tenant'
          hasPermanentAccess: boolean
          trialExpiredAt?: any | null
        }
      } | null
    }
  }
}

export const AuthCurrentUserDocument = gql`
  query AuthCurrentUser($companyId: ID!) {
    auth {
      userCurrent {
        user {
          ...AuthenticatedUserWithPositions
        }
      }
    }
  }
  ${AuthenticatedUserWithPositionsFragmentDoc}
`

/**
 * __useAuthCurrentUserQuery__
 *
 * To run a query within a React component, call `useAuthCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCurrentUserQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAuthCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthCurrentUserQuery,
    AuthCurrentUserQueryVariables
  > &
    (
      | { variables: AuthCurrentUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthCurrentUserQuery, AuthCurrentUserQueryVariables>(
    AuthCurrentUserDocument,
    options,
  )
}
export function useAuthCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthCurrentUserQuery,
    AuthCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthCurrentUserQuery,
    AuthCurrentUserQueryVariables
  >(AuthCurrentUserDocument, options)
}
export function useAuthCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AuthCurrentUserQuery,
        AuthCurrentUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AuthCurrentUserQuery,
    AuthCurrentUserQueryVariables
  >(AuthCurrentUserDocument, options)
}
export type AuthCurrentUserQueryHookResult = ReturnType<
  typeof useAuthCurrentUserQuery
>
export type AuthCurrentUserLazyQueryHookResult = ReturnType<
  typeof useAuthCurrentUserLazyQuery
>
export type AuthCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useAuthCurrentUserSuspenseQuery
>
export type AuthCurrentUserQueryResult = Apollo.QueryResult<
  AuthCurrentUserQuery,
  AuthCurrentUserQueryVariables
>
export const namedOperations = {
  Query: {
    AuthCurrentUser: 'AuthCurrentUser',
  },
}
