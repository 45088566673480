/**
 * @deprecated move necessary functions into
 * appropriate feature module
 * **/
import * as styles from '@common/styles/constants'
import * as yup from 'yup'

import {
  AddressArg,
  AuthRoleCodeEnum,
  Company,
  RepresentativeGroupEnum,
  User,
  UserPosition,
  UserStatusEnum,
} from '@generated/types.d'
import {
  ListQuestionProps,
  QuestionTypeList,
} from '@contexts/survey-builder/questions/list'
import {
  MatrixRatingQuestionProps,
  QuestionTypeMatrixRating,
} from '@contexts/survey-builder/questions/matrix-rating'
import {
  PqApolloClient,
  currentUser as currentUserGet,
  userGet,
  userUpsert,
} from '@api/index'
import {
  QuestionTypeRating,
  RatingQuestionProps,
} from '@contexts/survey-builder/questions/rating'

import { ApolloClient } from '@palqee/apollo-client'
import { DocumentQuestionProps } from '@contexts/survey-builder/questions/document'
import { DropdownMultiQuestionProps } from '@contexts/survey-builder/questions/dropdown-multi'
import { DropdownQuestionProps } from '@contexts/survey-builder/questions/dropdown'
import { FreeTextQuestionProps } from '@contexts/survey-builder/questions/free-text'
import { GetServerSidePropsContext } from 'next'
import { IntlFormatters } from '@palqee/intl'
import { MultipleSelectionQuestionProps } from '@contexts/survey-builder/questions/multiple-selection'
import { QuestionTypeCode } from '@models/types'
import { RecursivePartial } from 'index'
import Router from 'next/router'
import { SurveyBuilderQuestion } from '@contexts/survey-builder/questions/types'
import dayjs from 'dayjs'

export const getUserCompanyDepartment = (
  userPositions: RecursivePartial<UserPosition>[],
) => {
  return userPositions?.[0]?.department
}

export const getUserCompanyPosition = (
  userPositions: RecursivePartial<UserPosition>[],
  companyId: string,
) => {
  return userPositions?.find(
    (item) => item?.department?.companyId === companyId,
  )?.position
}

export function classNames(...items: string[]): string {
  return items.filter((a) => a).join(' ')
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const loadingHandler = (loading: any, data: any) => {
  if (loading) return null
  if (!data) return null
  return null
}

export const getColorFromString = (str: string): string => {
  const colorsArr = [
    styles.red1,
    styles.blue1,
    styles.green1,
    styles.purple1,

    styles.red2,
    styles.blue2,
    styles.green2,
    styles.purple2,

    styles.red3,
    styles.blue3,
    styles.green3,
    styles.purple3,

    styles.red4,
    styles.blue4,
    styles.green4,
    styles.purple4,
  ]
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const idx = hash % 16

  return colorsArr[idx]
}

export const uuidv4 = () => {
  return `_${Math.random().toString(36).substr(2, 9)}`
}

export const findMissingElement = <T>(
  currentArray: T[],
  previousArray: T[],
): { item: T; index: number } | null => {
  // loop through previous array
  for (let j = 0; j < previousArray.length; j++) {
    // look for same thing in new array
    if (currentArray.indexOf(previousArray[j]) === -1)
      return { item: previousArray[j], index: j }
  }

  return null
}

export const parseCookies = (cookie): Record<string, string> => {
  const rx = /([^;=\s]*)=([^;]*)/g
  const obj = {}
  /* eslint-disable-next-line */
  for (let m; (m = rx.exec(cookie)); ) obj[m[1]] = decodeURIComponent(m[2])
  return obj
}

export const parseCookiesFromRequest = (req) => {
  const cookie = req ? req.headers.cookie || '' : document.cookie
  return parseCookies(cookie)
}

export function array_move<T>(
  arr: T[],
  old_index: number,
  new_index: number,
): T[] {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1
    while (k !== -1) {
      arr.push(undefined)
      k -= 1
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}

/**
 * @deprecated use {@features/user/lib}
 * @todo delete and replace all existing references to new one
 */
export const parseFullNameToFirstLast = (name: string): string[] => {
  const spaceIdx = name.indexOf(' ')

  let firstName = name
  let lastName = ''
  if (spaceIdx !== -1) {
    firstName = name.slice(0, spaceIdx)
    lastName = name.slice(spaceIdx + 1, name.length)
  }

  return [firstName, lastName]
}

export const orderByDate = (a: string, b: string, dir: 'asc' | 'desc') => {
  if (dir === 'asc') {
    return dayjs(a).isAfter(dayjs(b)) ? 1 : -1
  }

  return dayjs(a).isBefore(dayjs(b)) ? 1 : -1
}

export const orderByString = (a: string, b: string, dir: 'asc' | 'desc') => {
  if (dir === 'asc') {
    if (a < b) return -1
    if (a > b) return 1
    return 0
  }

  if (a > b) return -1
  if (a < b) return 1
  return 0
}

export const validateFreeTextQuestion = (
  question: FreeTextQuestionProps,
): boolean => {
  if (question?.answer === null) {
    return false
  }

  return !!(question?.answer as any)?.length
}

export const validateDropdownQuestion = (
  question: DropdownQuestionProps,
): boolean => {
  return !!(question?.answer as any)?.value
}

export const validateDropdownMultiQuestion = (
  question: DropdownMultiQuestionProps,
): boolean => {
  return (question?.answers as any)
    .filter((a) => a)
    .every((answer) => !!answer.value)
}

export const validateDocumentQuestion = (
  question: DocumentQuestionProps,
): boolean => {
  return (
    !!(question?.answer as any)?.dontHave ||
    (!!(question?.answer as any)?.fileName?.length &&
      !!(question?.answer as any)?.file)
  )
}

export const validateListQuestion = (question: ListQuestionProps): boolean => {
  const answers = question?.answers ?? []
  for (let i = 0; i < answers.length; i++) {
    const answer = answers[i] as QuestionTypeList['answer']
    if (!(answer as any)?.value) return false
  }

  return true
}

export const validateMultipleChoiceQuestion = (
  question: MultipleSelectionQuestionProps,
): boolean => {
  // ensure at least one option from each row has been selected
  const answers = question?.answers ?? []
  for (let i = 0; i < answers.length; i++) {
    const answer = answers[i]
    if (answer) return true
  }

  return false
}

export const validateMatrixRatingQuestion = (
  question: QuestionTypeMatrixRating,
): boolean => {
  const answers =
    (question?.answers as QuestionTypeMatrixRating['answers']) ?? [[]]
  for (let i = 0; i < answers.length; i++) {
    const answerRow = answers[i] as QuestionTypeMatrixRating['answers']

    let isValid = false
    for (let j = 0; j < answerRow.length; j++) {
      const answerCell = answerRow[j]
      if (answerCell) {
        isValid = true
      }
    }

    if (!isValid) return false
  }

  return true
}

export const validateRatingQuestion = (
  question: QuestionTypeRating,
): boolean => {
  return !!question?.answer
}

export const validateSurveyQuestion = (
  question: SurveyBuilderQuestion,
): boolean => {
  let isValid = false
  switch (question.code) {
    case QuestionTypeCode.TEXT:
      isValid = validateFreeTextQuestion(question as FreeTextQuestionProps)
      break

    case QuestionTypeCode.DROPDOWN:
      isValid = validateDropdownQuestion(question as DropdownQuestionProps)
      break

    case QuestionTypeCode.ATTACHMENT_GENERIC:
      isValid = validateDocumentQuestion(question as DocumentQuestionProps)
      break

    case QuestionTypeCode.LIST:
      isValid = validateListQuestion(question as ListQuestionProps)
      break

    case QuestionTypeCode.CHOICE_MULTIPLE:
      isValid = validateMultipleChoiceQuestion(
        question as MultipleSelectionQuestionProps,
      )
      break

    case QuestionTypeCode.MATRIX:
      isValid = validateMatrixRatingQuestion(
        question as MatrixRatingQuestionProps,
      )
      break

    case QuestionTypeCode.RATING:
      isValid = validateRatingQuestion(question as RatingQuestionProps)
      break

    case QuestionTypeCode.DROPDOWN_MULTIPLE:
      isValid = validateDropdownMultiQuestion(
        question as DropdownMultiQuestionProps,
      )
      break

    default:
      throw new Error(
        `Question Type validation not supported: ${question.type}`,
      )
  }

  return isValid
}

export const validateSurveyQuestions = (
  questions: SurveyBuilderQuestion[],
): boolean => {
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i]

    const isValid = validateSurveyQuestion(question)
    if (!isValid) return false
  }

  return true
}

export interface CompanyDpoPublicInfo {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: AddressArg
  department?: string
  role?: string
  isDataPrivacyPractioner: boolean
}
export const getDpoPublicInfoFromCompany = async (
  client: PqApolloClient,
  company: Partial<Company> | any,
): Promise<null | CompanyDpoPublicInfo> => {
  const companySetDpoPublic = company?.representatives?.filter(
    (rep) => rep.representativeGroup === RepresentativeGroupEnum.Dpo,
  )

  if (companySetDpoPublic?.length) {
    const dpoPublic = companySetDpoPublic[0]
    return {
      firstName: dpoPublic?.firstName,
      lastName: dpoPublic?.lastName,
      email: dpoPublic?.email,
      phoneNumber: dpoPublic?.phoneNumber,
      isDataPrivacyPractioner: false,
      address: {
        streetAddress:
          dpoPublic?.address?.streetAddress ?? company?.address?.streetAddress,
        streetAddress2:
          dpoPublic?.address?.streetAddress2 ??
          company?.address?.streetAddress2,
        locality: dpoPublic?.address?.locality ?? company?.address?.locality,
        administrativeArea:
          dpoPublic?.address?.administrativeArea ??
          company?.address?.administrativeArea,
        postalCode:
          dpoPublic?.address?.postalCode ?? company?.address?.postalCode,
      },
    }
  }

  try {
    const companyUsersRes = await userGet(client, {
      input: {
        filter: {
          companyIds: [company?.id],
          roleCodes: [AuthRoleCodeEnum.Dpo, AuthRoleCodeEnum.Administrator],
          statuses: [UserStatusEnum.Active],
        },
      },
    })

    const usersList = companyUsersRes?.data?.user?.get?.users

    const companyDpoAdmin = usersList.filter((user) => {
      const hasAdminRole = user.roles.find(
        (role) => role.code === AuthRoleCodeEnum.Administrator,
      )
      return hasAdminRole
    })

    if (companyDpoAdmin.length > 0) {
      const dpoAdmin = companyDpoAdmin[0]
      return {
        firstName: dpoAdmin.firstName,
        lastName: dpoAdmin.lastName,
        email: dpoAdmin.email,
        phoneNumber: dpoAdmin.phoneNumber,
        isDataPrivacyPractioner: true,
        department: getUserCompanyDepartment(dpoAdmin.positions)?.name ?? '',
        role:
          getUserCompanyPosition(dpoAdmin.positions, company?.id as string)
            ?.name ?? '',
        address:
          {
            streetAddress: company?.address?.streetAddress || '',
            streetAddress2: company?.address?.streetAddress2 || '',
            locality: company?.address?.locality || '',
            administrativeArea: company?.address?.administrativeArea || '',
            postalCode: company?.address?.postalCode || '',
          } || null,
      }
    }
  } catch (err) {
    console.log('err: [getDpoPublicInfoFromCompany]', err)
    return null
  }

  return null
}

export const getAccountStatusTranslateId = (itemStatus: UserStatusEnum) => {
  switch (itemStatus) {
    case UserStatusEnum.Pending:
      return 'PENDING'

    case UserStatusEnum.Active:
      return 'ACTIVE_2'

    case UserStatusEnum.Deactivated:
      return 'DEACTIVATED'

    default:
      return 'PENDING'
  }
}

export const redirectTo = async (
  ctx: GetServerSidePropsContext,
  location: string,
  props?: { language: string },
) => {
  const { res } = ctx

  if (typeof window !== 'undefined') {
    await Router.push(location, location, {
      locale: props?.language ?? ctx?.locale,
    })
  } else {
    if (res) {
      res.writeHead(302, { Location: location })
      res.end()
    }
  }
}

export const calculatePercentage = (part: number, total: number) =>
  total === 0 ? 0 : (part / total) * 100

const minPasswordLen = 6
const capitalLetterReg = /[A-Z]/
const lowerLetterReg = /[a-z]/
const atleastOneNumReg = /[0-9]/

export const passwordValidations = (intl: IntlFormatters) => {
  return [
    {
      type: 'required',
      params: [
        intl.formatMessage({
          id: 'PASSWORD_REQUIRED',
          defaultMessage: 'Password is required',
        }),
      ],
    },
    {
      type: 'min',
      params: [
        minPasswordLen,
        intl.formatMessage({
          id: 'PASSWORD_TOO_SHORT',
          defaultMessage: 'Password is too short',
        }),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresCapital',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_CAPITAL_LETTER',
          defaultMessage: 'Password requires at least one capital letter',
        }),
        (currVal) => capitalLetterReg.test(currVal),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresLower',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_LOWER_LETTER',
          defaultMessage: 'Password requires at least one lowercase letter ',
        }),
        (currVal) => lowerLetterReg.test(currVal),
      ],
    },
    {
      type: 'test',
      params: [
        'PassRequiresNumber',
        intl.formatMessage({
          id: 'PASSWORD_REQUIRES_ONE_NUMBER',
          defaultMessage: 'Password requires at least one number',
        }),
        (currVal) => atleastOneNumReg.test(currVal),
      ],
    },
  ]
}

export const passwordValidationSchema = (intl: IntlFormatters) => {
  return yup
    .string()
    .required(
      intl.formatMessage({
        id: 'PASSWORD_REQUIRED',
        defaultMessage: 'Password is required',
      }),
    )
    .min(
      minPasswordLen,
      intl.formatMessage({
        id: 'PASSWORD_TOO_SHORT',
        defaultMessage: 'Password is too short',
      }),
    )
    .test(
      'PassRequiresCapital',
      intl.formatMessage({
        id: 'PASSWORD_REQUIRES_ONE_CAPITAL_LETTER',
        defaultMessage: 'Password requires at least one capital letter',
      }),
      (currVal) => capitalLetterReg.test(currVal),
    )
    .test(
      'PassRequiresLower',
      intl.formatMessage({
        id: 'PASSWORD_REQUIRES_ONE_LOWER_LETTER',
        defaultMessage: 'Password requires at least one lowercase letter ',
      }),
      (currVal) => lowerLetterReg.test(currVal),
    )
    .test(
      'PassRequiresNumber',
      intl.formatMessage({
        id: 'PASSWORD_REQUIRES_ONE_NUMBER',
        defaultMessage: 'Password requires at least one number',
      }),
      (currVal) => atleastOneNumReg.test(currVal),
    )
}

export const setLastCompanyId = async (
  client: ApolloClient<any>,
  defaultCompanyId: string,
) => {
  try {
    const currentUserRes = await currentUserGet(client, undefined, undefined, {
      fetchPolicy: 'cache-first',
    })
    const currentUser = currentUserRes?.data?.auth?.userCurrent?.user as User
    if (currentUser) {
      await userUpsert(client, {
        userId: currentUser?.id,
        defaultCompanyId,
      })
    }
  } catch (err) {
    console.log('Error while trying to set default company...', err)
  }
}

export const lineBreaksToHTML = (content: string): string => {
  return content.replace(/(?:\r\n|\r|\n|\\n)/g, '<br>')
}

export const isObject = (key: string): boolean => key?.indexOf('.') >= 0

export const isObjectEmpty = (
  obj: Record<string, unknown>, // because Object.keys(new Date()).length === 0;
): boolean =>
  // we have to do some additional check
  obj && // 👈 null and undefined check
  Object.keys(obj).length === 0 &&
  obj.constructor === Object

export const updateObject = (target, update) => {
  // for each key/value pair in update object
  Object.entries(update).forEach(([key, value]) => {
    // if target has the relevant key and
    // the type in target and update is the same
    if (target.hasOwnProperty(key) && typeof value === typeof target[key]) {
      // update value if string,number or boolean
      if (
        ['string', 'number', 'boolean'].includes(typeof value) ||
        Array.isArray(value)
      ) {
        target[key] = value
      } else {
        // if type is object then go one level deeper
        if (typeof value === 'object') {
          updateObject(target[key], value)
        }
      }
    }
  })
}

export const digest = async ({ algorithm = 'SHA-256', message }) =>
  Array.prototype.map
    .call(
      new Uint8Array(
        await crypto.subtle.digest(
          algorithm,
          new TextEncoder().encode(message),
        ),
      ),
      (x) => ('0' + x.toString(16)).slice(-2),
    )
    .join('')

export const arrayUniqueByKey = (data, key) => [
  ...new Map(data.map((item) => [item[key], item])).values(),
]

export const capitalizeEachWord = (s) =>
  s
    ?.toLocaleLowerCase()
    .replace('_', ' ')
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())

export const setStringType = (value: string): string => value || ''
export const setArrayType = (value: unknown[]): unknown[] => value || []
export const setNumberType = (value: number): number => value || 0
