import { TypePolicies } from '@palqee/apollo-client'

export const query = (): TypePolicies => {
  return {
    Query: {
      fields: {
        survey: {
          merge: true,
        },
        document: {
          merge: true,
        },
        dsar: {
          merge: true,
        },
      },
    },
  }
}
