import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { FloatingOverlay } from '@floating-ui/react'
import { floatingOverlay } from './popper.css.ts'

const Overlay = (props: PropsWithChildren) => {
  const floatingOverlayClasses = classNames(
    'pq-popper__overlay',
    floatingOverlay,
  )
  return (
    <FloatingOverlay className={floatingOverlayClasses} lockScroll>
      {props?.children}
    </FloatingOverlay>
  )
}

export { Overlay }
