const cache = {}

export const getLocalStorageItem = (key) => {
  if (typeof window === 'undefined') {
    return undefined
  }
  try {
    return localStorage.getItem(key)
  } catch (error) {
    console.warn('get localStorage not supported')
  }
}

export const setLocalStorageItem = (key, value) => {
  try {
    cache[key] = value
    localStorage.setItem(key, value)
  } catch (error) {
    console.warn('set localStorage not supported')
  }
}

export const deleteLocalStorageItem = (key) => {
  if (typeof window === 'undefined') {
    return undefined
  }
  try {
    return localStorage.removeItem(key)
  } catch (error) {
    console.warn('get localStorage not supported')
  }
}
