import { createHttpLink, setAuthorizationLink } from './links'
import { ApolloClientProps, TApolloClient } from '/@hooks/use-apollo/types'
import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'

let globalApolloClient: TApolloClient

/**
 * Creates and configures the ApolloClient
 */
export const createApolloClient = (props: ApolloClientProps) => {
  const ssrMode = typeof window === 'undefined'

  const cache = new InMemoryCache(props?.inMemoryCacheConfig)

  // Check out https://github.com/zeit/next.js/pull/4611
  const client = new ApolloClient({
    ssrMode,
    cache,
    link: from([
      ...(props?.apolloClientLink ?? []),
      setAuthorizationLink(props?.headers),
      createHttpLink(),
    ]),
    ...props?.apolloClientOptions,
  })

  client['token'] = props?.headers?.authorization ?? ''

  return client
}

const mergeData = (existingCache, initialState) => {
  return mergeWith({}, existingCache, initialState, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return [
        ...srcValue,
        ...objValue.filter((d) => srcValue.every((s) => !isEqual(d, s))),
      ]
    }
  })
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
export const initializeApollo = (
  initialState = null,
  config?: ApolloClientProps,
) => {
  const token = config?.headers?.authorization ?? ''

  let _apolloClient = globalApolloClient

  // Check if the token has changed
  if (!_apolloClient || _apolloClient['token'] !== token) {
    _apolloClient = createApolloClient(config)

    if (typeof window !== 'undefined') {
      globalApolloClient = _apolloClient
      globalApolloClient['token'] = token // Store the token in the client instance
    }
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = mergeData(existingCache, initialState)

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!globalApolloClient) globalApolloClient = _apolloClient

  return _apolloClient
}
