const breakpoints = {
  small: 400,
  medium: 768,
  large: 1024,
  'w-1276': 1276,
  xLarge: 1280,
  xLarge2: 1366,
  'w-1440': 1440,
  '2XLarge': 1600,
  '3XLarge': 1916,
}

type BreakPoint = keyof typeof breakpoints
type MediaQueryType = 'max-width' | 'min-width'

const setNumberBasedOnType = (n: number, type: MediaQueryType = 'max-width') =>
  type === 'min-width' ? n - 1 : n

export const breakPoint = (n: BreakPoint, type: MediaQueryType = 'max-width') =>
  `${setNumberBasedOnType(breakpoints[n], type)}px`

export const mediaQuery = (n: BreakPoint, type: MediaQueryType = 'max-width') =>
  `@media (${type}: ${breakPoint(n, type)})`

export const createMediaQueryObject = (mediaStyles) => {
  const mediaQueryObject = { '@media': {} }

  Object.keys(mediaStyles).forEach((key) => {
    if (key in breakpoints) {
      mediaQueryObject['@media'][mediaQuery(key as BreakPoint)] =
        mediaStyles[key]
    } else {
      mediaQueryObject['@media'][key] = mediaStyles[key]
    }
  })

  return mediaQueryObject
}
