import { DebounceAsyncFunc } from './types'

export const debounceAsync: DebounceAsyncFunc<any> = (func, wait) => {
  let timeout: ReturnType<typeof setTimeout>

  return (...args) =>
    new Promise((resolve, reject) => {
      if (timeout) clearTimeout(timeout)

      timeout = setTimeout(async () => {
        try {
          const result = await func(...args)
          resolve(result)
        } catch (error) {
          reject(error)
        }
      }, wait)
    })
}
