import {
  AuthCurrentUserDocument,
  AuthCurrentUserQueryVariables,
} from '@features/auth/operations/query/__generated__/auth-user'
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthCurrentUserLanguageDocument,
  UserGetDocument,
  UserGetQueryVariables,
  UserPositionSetDocument,
  UserPositionSetMutationVariables,
  UserUpsertDocument,
  UserUpsertMutationVariables,
} from '@generated/types.d'
import { PqApolloClient } from './index'
import {
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client'
import { UserCreateOrLinkToCompanyMutationVariables } from '@generated/gql/graphql'

export const userUpsert = async (
  client: PqApolloClient,
  variables: UserUpsertMutationVariables,
  document: typeof UserUpsertDocument = UserUpsertDocument,
  options?: Partial<
    MutationOptions<any, UserUpsertMutationVariables> & {
      useLocale: boolean
      language?: string
    }
  >,
) => {
  const { useLocale, language, ...newOptions } = options || {}
  const languageOption = useLocale ? { language } : {}

  return client.mutate({
    variables: {
      ...variables,
      ...languageOption,
    },
    mutation: document,
    ...newOptions,
  })
}

export const userCreateOrLink = async (
  client: PqApolloClient,
  variables: UserCreateOrLinkToCompanyMutationVariables,
  document: typeof UserUpsertDocument = UserUpsertDocument,
  options?: Partial<
    MutationOptions<any, UserCreateOrLinkToCompanyMutationVariables> & {
      useLocale: boolean
      language?: string
    }
  >,
) => {
  const { useLocale, language, ...newOptions } = options || {}
  const languageOption = useLocale ? { language } : {}

  return client.mutate({
    variables: {
      ...variables,
      ...languageOption,
    },
    mutation: document,
    ...newOptions,
  })
}

export const userPositionSet = async (
  client: PqApolloClient,
  variables: UserPositionSetMutationVariables,
  document: typeof UserPositionSetDocument = UserPositionSetDocument,
  options?: Partial<MutationOptions<any, UserUpsertMutationVariables>>,
) => {
  return client.mutate({
    variables,
    mutation: document,
    ...options,
  })
}

export const userGet = async (
  client: PqApolloClient,
  variables: UserGetQueryVariables,
  graphqlDocument: typeof UserGetDocument = UserGetDocument,
) => {
  const userGetRes = await client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'cache-first',
  })
  client.writeQuery({
    query: graphqlDocument,
    data: { ...userGetRes?.data },
  })

  return userGetRes
}

export const currentUser = async (
  client: PqApolloClient,
  variables: AuthCurrentUserQueryVariables,
  graphqlDocument: typeof AuthCurrentUserDocument = AuthCurrentUserDocument,
  options?: Partial<QueryOptions<OperationVariables>>,
) => {
  return client.query({
    query: graphqlDocument,
    variables,
    ...options,
  })
}

export const currentLanguage = async (
  client: PqApolloClient,
  graphqlDocument: typeof AuthCurrentUserLanguageDocument = AuthCurrentUserLanguageDocument,
  options?: Partial<QueryOptions<OperationVariables>>,
) => {
  const currentUserRes = await client.query({
    ...options,
    query: graphqlDocument,
    fetchPolicy: 'network-only',
  })
  return currentUserRes
}
