import { fetchWithRetry } from './fetch'

export const getFileFromUrl = async (
  url: string,
  filename = '',
  cb?: (file: File) => void,
) => {
  return await fetchWithRetry(url)
    .then((r) => r.blob())
    .then((blobFile) => {
      const file = new File([blobFile], filename, {
        type: blobFile.type,
      })

      if (file && cb) return cb(file)

      return file
    })
}
