import * as Types from '../../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import {
  SurveyDetailsFragmentDoc,
  ISurveyAnswerOptionDetailsFragmentDoc,
  SurveySubcategoryTemplateDetailsFragmentDoc,
  SurveyGetBuilderFragmentDoc,
} from '../../../../../../features/core/graphql/survey/__generated__/fragments'
import { CompanySelectedRegulationFragmentDoc } from '../../../../../../features/core/graphql/company/__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SurveyBuilderRegulatoryGetPageQueryVariables = Types.Exact<{
  surveyFilterArg?: Types.InputMaybe<Types.SurveyFilterArg>
}>

export type SurveyBuilderRegulatoryGetPageQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    get: {
      __typename: 'SurveyGetPayload'
      surveys: Array<{
        __typename: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: Types.SurveyType
        createdAt: any
        lastUsedAt?: any | null
        company: {
          __typename: 'Company'
          id: string
          selectedRegulations: Array<{
            __typename: 'Regulation'
            id: string
            regulationId: string
            name: string
            uiCode?: any | null
            logoPath?: string | null
            description?: string | null
          }>
          currentSubscriptionTier?: {
            __typename: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }
        sessions: Array<{
          __typename: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename: 'SurveyCategory'
              id: string
              regulation?: {
                __typename: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }>
    }
  }
}

export const SurveyBuilderRegulatoryGetPageDocument = gql`
  query SurveyBuilderRegulatoryGetPage($surveyFilterArg: SurveyFilterArg) {
    survey {
      get(input: { filter: $surveyFilterArg }) {
        surveys {
          ...SurveyDetails
          company {
            id
            selectedRegulations {
              ...CompanySelectedRegulation
            }
            currentSubscriptionTier {
              id
              name
              features {
                id
                name
                code
                description
                ... on SubscriptionFeatureIntRange {
                  minValue
                  maxValue
                }
                ... on SubscriptionFeatureBoolean {
                  available
                }
              }
            }
          }
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
  ${CompanySelectedRegulationFragmentDoc}
`

/**
 * __useSurveyBuilderRegulatoryGetPageQuery__
 *
 * To run a query within a React component, call `useSurveyBuilderRegulatoryGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyBuilderRegulatoryGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyBuilderRegulatoryGetPageQuery({
 *   variables: {
 *      surveyFilterArg: // value for 'surveyFilterArg'
 *   },
 * });
 */
export function useSurveyBuilderRegulatoryGetPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyBuilderRegulatoryGetPageQuery,
    SurveyBuilderRegulatoryGetPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyBuilderRegulatoryGetPageQuery,
    SurveyBuilderRegulatoryGetPageQueryVariables
  >(SurveyBuilderRegulatoryGetPageDocument, options)
}
export function useSurveyBuilderRegulatoryGetPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyBuilderRegulatoryGetPageQuery,
    SurveyBuilderRegulatoryGetPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyBuilderRegulatoryGetPageQuery,
    SurveyBuilderRegulatoryGetPageQueryVariables
  >(SurveyBuilderRegulatoryGetPageDocument, options)
}
export function useSurveyBuilderRegulatoryGetPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        SurveyBuilderRegulatoryGetPageQuery,
        SurveyBuilderRegulatoryGetPageQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyBuilderRegulatoryGetPageQuery,
    SurveyBuilderRegulatoryGetPageQueryVariables
  >(SurveyBuilderRegulatoryGetPageDocument, options)
}
export type SurveyBuilderRegulatoryGetPageQueryHookResult = ReturnType<
  typeof useSurveyBuilderRegulatoryGetPageQuery
>
export type SurveyBuilderRegulatoryGetPageLazyQueryHookResult = ReturnType<
  typeof useSurveyBuilderRegulatoryGetPageLazyQuery
>
export type SurveyBuilderRegulatoryGetPageSuspenseQueryHookResult = ReturnType<
  typeof useSurveyBuilderRegulatoryGetPageSuspenseQuery
>
export type SurveyBuilderRegulatoryGetPageQueryResult = Apollo.QueryResult<
  SurveyBuilderRegulatoryGetPageQuery,
  SurveyBuilderRegulatoryGetPageQueryVariables
>
export const namedOperations = {
  Query: {
    SurveyBuilderRegulatoryGetPage: 'SurveyBuilderRegulatoryGetPage',
  },
}
