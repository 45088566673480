import { TypePolicies } from '@palqee/apollo-client'
import { hashKeyArgs } from './helpers'

export const statistics = (): TypePolicies => {
  return {
    NamespaceStatisticsQuery: {
      merge: (_, incoming) => {
        return incoming
      },
      fields: {
        maturityScoreAverages: {
          keyArgs: hashKeyArgs,
        },
        tasksCount: {
          keyArgs: hashKeyArgs,
        },
      },
    },
    StatisticsMaturityScoreAveragesItem: {
      merge: true,
      keyFields: ['sessionId'],
    },
  }
}
