import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import { CompanyBinaryFileInfoFragmentDoc } from '../../company/__generated__/fragments'
export type AuthenticatedUserWithPositionsFragment = {
  __typename: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: Types.UserStatusEnum
  language?: Types.LanguageCodeEnum | null
  defaultCompanyId?: string | null
  positions: Array<{
    __typename: 'UserPosition'
    departmentId: string
    positionId: string
    position: {
      __typename: 'Position'
      id: string
      name: string
      companyId: string
    }
    department: {
      __typename: 'Department'
      id: string
      name: string
      companyId: string
    }
  }>
  defaultCompany?: {
    __typename: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename: 'Industry'; id: string; name: string }>
    address?: {
      __typename: 'Address'
      id: string
      country: { __typename: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: Types.AuthRoleCodeEnum
  }>
  tenant: {
    __typename: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export type AuthenticatedUserFragment = {
  __typename: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  phoneNumber?: any | null
  passwordLastChangeAt?: any | null
  isMfaEnabled?: boolean | null
  email: any
  isAdmin?: boolean | null
  isPalqeeManager?: boolean | null
  isRegistrationComplete?: boolean | null
  status: Types.UserStatusEnum
  language?: Types.LanguageCodeEnum | null
  defaultCompanyId?: string | null
  defaultCompany?: {
    __typename: 'Company'
    id: string
    name?: string | null
    numberOfEmployees: any
    industries: Array<{ __typename: 'Industry'; id: string; name: string }>
    address?: {
      __typename: 'Address'
      id: string
      country: { __typename: 'Country'; id: string; name: string }
    } | null
  } | null
  companies: Array<{
    __typename: 'Company'
    id: string
    name?: string | null
    logo?: {
      __typename: 'BinaryFileInfoPublic'
      publicUrl?: string | null
      id: string
      isPublic: boolean
      originalFilename?: string | null
      mimetype: string
      url: string
    } | null
    currentSubscriptionTier?: {
      __typename: 'SubscriptionTier'
      id: string
      name: string
      features?: Array<
        | {
            __typename: 'SubscriptionFeatureBoolean'
            available: boolean
            id: string
            name: string
            code: string
            description?: string | null
          }
        | {
            __typename: 'SubscriptionFeatureIntRange'
            minValue?: number | null
            maxValue?: number | null
            id: string
            name: string
            code: string
            description?: string | null
          }
      > | null
    } | null
  }>
  roles: Array<{
    __typename: 'UserRole'
    userId: string
    roleId: string
    name: string
    uiCode: any
    companyId?: string | null
    code: Types.AuthRoleCodeEnum
  }>
  tenant: {
    __typename: 'Tenant'
    hasPermanentAccess: boolean
    trialExpiredAt?: any | null
  }
}

export const AuthenticatedUserFragmentDoc = gql`
  fragment AuthenticatedUser on User {
    id
    firstName
    lastName
    fullName @client
    phoneNumber
    passwordLastChangeAt
    isMfaEnabled
    email
    isAdmin @client
    isPalqeeManager @client
    isRegistrationComplete @client
    status
    language
    defaultCompanyId
    defaultCompany {
      id
      name
      numberOfEmployees
      industries {
        id
        name
      }
      address {
        id
        country {
          id
          name
        }
      }
    }
    companies {
      id
      name
      logo {
        ...CompanyBinaryFileInfo
      }
      currentSubscriptionTier {
        id
        name
        features {
          id
          name
          code
          description
          ... on SubscriptionFeatureIntRange {
            minValue
            maxValue
          }
          ... on SubscriptionFeatureBoolean {
            available
          }
        }
      }
    }
    roles {
      userId
      roleId
      name
      uiCode
      companyId
      code
    }
    tenant {
      hasPermanentAccess
      trialExpiredAt
    }
  }
  ${CompanyBinaryFileInfoFragmentDoc}
`
export const AuthenticatedUserWithPositionsFragmentDoc = gql`
  fragment AuthenticatedUserWithPositions on User {
    ...AuthenticatedUser
    positions(input: { filter: { companyIds: [$companyId] } }) {
      departmentId
      positionId
      position {
        id
        name
        companyId
      }
      department {
        id
        name
        companyId
      }
    }
  }
  ${AuthenticatedUserFragmentDoc}
`
export const namedOperations = {
  Fragment: {
    AuthenticatedUserWithPositions: 'AuthenticatedUserWithPositions',
    AuthenticatedUser: 'AuthenticatedUser',
  },
}
