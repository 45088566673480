import { TaskGetPayload, TaskHistoryItemsGetPayload } from '@generated/types.d'
import { TypePolicies } from '@apollo/client'
import { hashKeyArgs } from './helpers'
import { offsetLimitPagination } from '../helpers/pagination'

export const task = (): TypePolicies => {
  return {
    NamespaceTaskQuery: {
      merge: true,
      fields: {
        get: offsetLimitPagination({
          field: 'tasks',
          keyArgs: ({ input: inputParam }) => {
            const filterArg = {
              ...inputParam.filter,
            }

            const inputArg = {
              input: {
                filter: filterArg,
                search: inputParam?.search,
                order: inputParam?.order,
              },
            }

            return hashKeyArgs(inputArg)
          },
          paginationInfoHandler: (merged: TaskGetPayload) => {
            return {
              ...merged?.paginationInfo,
              offset: 0,
              limit: merged?.tasks?.length,
            }
          },
        }),
        statusesGet: {
          keyArgs: hashKeyArgs,
        },
        categoriesGet: {
          keyArgs: hashKeyArgs,
        },
        historyItemsGet: offsetLimitPagination({
          field: 'historyItems',
          keyArgs: ['input', ['filter']],
          paginationInfoHandler: (merged: TaskHistoryItemsGetPayload) => {
            return {
              ...merged?.paginationInfo,
              offset: 0,
              limit: merged?.historyItems?.length,
            }
          },
        }),
      },
    },
    Task: {
      merge: true,
      fields: {
        attachments: {
          merge: (_, incoming = []) => {
            return incoming
          },
        },
      },
    },
  }
}
