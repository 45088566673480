export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AuthLoginConfirmPayload: [
      'AuthLoginNexChallengePayload',
      'AuthLoginSuccessPayload',
    ],
    AuthLoginPayload: [
      'AuthLoginNexChallengePayload',
      'AuthLoginSuccessPayload',
    ],
    DsarRequestConversationUnion: ['DsarRequestComment', 'DsarRequestMessage'],
    GeneralSearchMainEntitiesResultEntityUnion: [
      'DocboxDocument',
      'DsarForm',
      'DsarRequest',
      'Survey',
      'SurveySession',
      'Task',
    ],
    GeneralSearchResultEntityUnion: [
      'DocboxDocument',
      'DsarForm',
      'DsarRequest',
      'DsarRequestType',
      'Survey',
      'SurveySubcategory',
      'Task',
      'TaskCategory',
      'TaskPriority',
      'TaskStatus',
    ],
    IBinaryFileInfo: ['BinaryFileInfo', 'BinaryFileInfoPublic'],
    IDatamapEntity: [
      'Asset',
      'DataElement',
      'DataLifecycle',
      'DataOwnership',
      'DataSharing',
      'DataSource',
      'DataSubject',
      'Department',
      'LegalBasis',
      'Process',
      'ProcessingType',
      'Region',
      'SurveyAnswerOption',
    ],
    IDatamapResponse: [
      'DatamapDataElementResponse',
      'DatamapDataSharingResponse',
      'DatamapDataSourceResponse',
      'DatamapResponse',
    ],
    ISubscriptionFeature: [
      'SubscriptionFeatureBoolean',
      'SubscriptionFeatureIntRange',
    ],
    ISurveyAnswerOption: [
      'DatamapAnswerOption',
      'DatamapCustomAnswerOption',
      'SurveyAnswerOption',
      'SurveyVirtualAnswerOption',
    ],
    IUserWithEmail: ['DsarRequestor', 'Person', 'User'],
  },
}
export default result
