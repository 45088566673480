import { audience } from './audience'
import { company } from './company'
import { document } from './document'
import { dsar } from './dsar'
import { general } from './general'
import { query } from './query'
import { statistics } from './statistics'
import { survey } from './survey'
import { task } from './task'
import { user } from './user'
import { consent } from './consent'
import { composeTypePolicies } from './helpers'
import { auth } from './auth'
import { InMemoryCacheConfig } from '@apollo/client'
import { dashboard } from './dashboard'

// @todo must be a better way of doing this
export const typePolicies = (): InMemoryCacheConfig => {
  const policies = composeTypePolicies(
    auth,
    query,
    user,
    survey,
    company,
    general,
    document,
    statistics,
    dsar,
    task,
    audience,
    consent,
    dashboard,
  )
  return {
    typePolicies: policies,
  }
}
