import classNames from 'classnames'
import { PropsWithChildren, forwardRef } from 'react'
import { DialogTriggerProps } from './types'
import { PopperTrigger } from '/@popper'

const DialogTrigger = forwardRef<
  Element,
  PropsWithChildren<DialogTriggerProps>
>((props, propRef) => {
  const { children, className, ...restProps } = props

  const classes = classNames('pq-dialog__trigger', className)

  return (
    <PopperTrigger className={classes} ref={propRef} {...restProps}>
      {children}
    </PopperTrigger>
  )
})

DialogTrigger.displayName = 'DialogTrigger'

export { DialogTrigger }
