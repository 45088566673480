import '@features/core/sentry/init'
import '@common/styles/global.css'
import 'dayjs/locale/pt'
import 'dayjs/locale/es'

import App, { AppContext, NextWebVitalsMetric } from 'next/app'

import AppBootstrap from '@app-page/bootstrap'
import { GetInitialProps } from '@app-page/getinitialprops'

/**
 * All props returned by "getInitialProps", "getServerSideProps" or "getStaticProps" are available in "props.pageProps".
 * The "Component" prop within "props.pageProps" contains the page that is being rendered.
 *
 * @return {JSX.Element}
 */
const PqApp = (props) => {
  return <AppBootstrap {...props} />
}

/**
 * Handles some initial props
 *
 * - Setting locale and getting messages based on locale
 *
 * @param appContext
 * @returns {AppInitialProps & ISelectedLocaleState}
 */
const getInitialProps: typeof App.getInitialProps = async (
  appContext: AppContext,
) => {
  return GetInitialProps(appContext)
}

// @todo send web vitals to GA or Amplitude
export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.debug('metric ***', metric)
}

PqApp.getInitialProps = getInitialProps

export default PqApp
