import * as react from 'react'

/**
 * Combines many refs into one. Useful for combining many ref hooks
 */
export const useCombinedRefs = (
  ...refs:
    | react.MutableRefObject<HTMLElement>[]
    | any[]
    | react.ForwardedRef<HTMLElement>[]
) =>
  react.useCallback(
    (element: HTMLElement) =>
      refs.forEach(
        (
          ref:
            | react.MutableRefObject<HTMLElement>
            | any
            | react.ForwardedRef<HTMLElement>,
        ) => {
          if (!ref) {
            return
          }

          // Ref can have two types - a function or an object. We treat each case.
          if (typeof ref === 'function') {
            return ref(element)
          }

          // As per https://github.com/facebook/react/issues/13029
          // it should be fine to set current this way.
          ref.current = element
        },
      ),
    [refs],
  )
