import { usePostHog as usePostHogClient } from 'posthog-js/react'
import { useCallback } from 'react'
import { PosthogEvent, UsePostHogReturn, PosthogGroupProps } from './types'

export const usePostHog = <T>(props?: {
  groupId?: string
  groupName?: string
}): UsePostHogReturn<T> => {
  const client = usePostHogClient()

  const { groupId, groupName } = props ?? {}

  const setPosthogGroup = useCallback(
    (groupProps?: PosthogGroupProps) => {
      const {
        groupName: gName,
        // , groupId: gId, data = {}
      } = groupProps ?? {}
      console.log(`setPosthogGroup temporarily disabled  with props ${gName}`)
      // below has been temporarily disabled as this increased our costs
      // and took us out of fremium re-add once we have enough loot
      // client.group(groupName ?? gName, groupId ?? gId, {
      //   ...data,
      // })
    },
    [client, groupName, groupId],
  )

  const capturePosthogEvent = (
    eventName: string,
    data: PosthogEvent<T>,
    groupProps?: PosthogGroupProps,
  ) => {
    if (client?.__loaded) {
      setPosthogGroup(groupProps)
      client.capture(eventName, data)
    }
  }

  const identifyPosthog = useCallback(
    (distinct_id: string, groupProps?: PosthogGroupProps) => {
      if (client?.__loaded) {
        setPosthogGroup(groupProps)
        client.identify(distinct_id)
      }
    },
    [client, setPosthogGroup],
  )

  const setPosthogPeople = useCallback(
    (
      event: PosthogEvent<T>,
      ingestMethod: 'set' | 'set_once' = 'set',
      groupProps?: PosthogGroupProps,
    ) => {
      if (client?.__loaded) {
        setPosthogGroup(groupProps)
        client.people[ingestMethod](event)
      }
    },
    [client?.__loaded, client?.people, setPosthogGroup],
  )

  const resetPosthog = useCallback(() => {
    if (client?.__loaded) client.reset()
  }, [client])

  return [
    client,
    {
      capturePosthogEvent,
      setPosthogPeople,
      identifyPosthog,
      resetPosthog,
      setPosthogGroup,
    },
  ]
}
