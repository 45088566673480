import { IndexDbConfig } from './types'

export const initIndexedDb = (config: IndexDbConfig) => {
  const { dbName, version = 1, stores } = config

  let db: IDBDatabase

  const openDB = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version)

      request.onupgradeneeded = () => {
        db = request.result
        for (const [storeName, options] of Object.entries(stores)) {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, options)
          }
        }
      }

      request.onsuccess = () => {
        db = request.result
        resolve(db)
      }

      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  const addDataToStore = async <T>(
    storeName: string,
    data: T,
  ): Promise<T | string | null> => {
    try {
      const indexedDb = await openDB()
      const tx = indexedDb.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)

      return new Promise<T | string | null>((resolve, reject) => {
        const request = store.add(data)
        request.onsuccess = () => {
          resolve(data)
        }
        request.onerror = () => {
          reject(request.error?.message || 'Unknown error')
        }
      })
    } catch (error) {
      return (error as Error).message || 'Unknown error'
    }
  }

  const getDataFromStore = async <T>(
    storeName: string,
    id: string,
  ): Promise<T | null> => {
    const indexedDb = await openDB()
    const tx = indexedDb.transaction(storeName, 'readonly')
    const store = tx.objectStore(storeName)

    return new Promise<T | null>((resolve, reject) => {
      const request = store.get(id)
      request.onsuccess = () => {
        resolve(request.result)
      }
      request.onerror = () => {
        reject(request.error)
      }
    })
  }

  const deleteItemFromStore = async (
    storeName: string,
    id: string | number,
  ): Promise<boolean> => {
    try {
      const indexedDb = await openDB()
      const tx = indexedDb.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)

      return new Promise<boolean>((resolve) => {
        const deleteRequest = store.delete(id)
        deleteRequest.onsuccess = () => {
          resolve(true)
        }
        deleteRequest.onerror = () => {
          resolve(false)
        }
      })
    } catch {
      return false
    }
  }

  const clearStore = async (storeName: string): Promise<boolean> => {
    try {
      const indexedDb = await openDB()
      const tx = indexedDb.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)

      return new Promise<boolean>((resolve) => {
        const clearRequest = store.clear()
        clearRequest.onsuccess = () => {
          resolve(true)
        }
        clearRequest.onerror = () => {
          console.error('Failed to clear cache:', clearRequest.error)
          resolve(false)
        }
      })
    } catch (error) {
      console.error('Failed to open IndexedDB:', error)
      return false
    }
  }

  // Return all utility functions that have access to the configuration.
  return {
    addDataToStore,
    getDataFromStore,
    deleteItemFromStore,
    clearStore,
  }
}
