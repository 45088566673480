import styled from '@emotion/styled'
import classNames from 'classnames'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'

const CardHeaderActions = (
  props: PropsWithChildren<ComponentPropsWithoutRef<'div'>>,
) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-card__header-actions', className)

  return (
    <CardHeaderActionsWrapper className={classes} {...rest}>
      {children}
    </CardHeaderActionsWrapper>
  )
}

const CardHeaderActionsWrapper = styled.div``

export { CardHeaderActions }
