/**
 * Fetch with retries and exponential backoff.
 *
 * @param url The URL to fetch.
 * @param options Fetch options.
 * @param retries Number of retries.
 * @param delay Delay between retries in milliseconds.
 * @returns The fetch response.
 */
export async function fetchWithRetry(
  url: string,
  options: RequestInit = {},
  retries = 3,
  delay = 300,
): Promise<Response> {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        // Throw an error to catch it below and retry
        throw new Error(
          `Request failed with status ${response.status} on ${url}`,
        )
      }
      return response // Return the response if request was successful
    } catch (error) {
      console.log(`Attempt ${i + 1} failed: ${(error as Error).message}`)
      if (i === retries - 1) throw error // If this was the last attempt, throw the error
      await new Promise((resolve) => setTimeout(resolve, delay)) // Wait for the specified delay
      delay *= 2 // Optional: Implement exponential backoff by doubling the delay
    }
  }
  // To satisfy TypeScript's need for a return, but this line is unreachable due to the throw above
  throw new Error('All fetch attempts failed')
}
