import { DsarRequestCommentsGetPayload } from '@generated/types.d'
import { TypePolicies } from '@palqee/apollo-client'
import { hashKeyArgs } from './helpers'
import { offsetLimitPagination } from '../helpers/pagination'
import { setFullNameType } from '../lib'

export const dsar = (): TypePolicies => {
  return {
    NamespaceDsarQuery: {
      merge: true,
      fields: {
        formsGet: {
          keyArgs: ({ input }) => {
            const { filter } = input ?? {}
            const inputArg = {
              input: {
                filter: {
                  ...filter,
                  formIds: !!filter.formIds ? filter.formIds : undefined,
                },
              },
            }
            return hashKeyArgs(inputArg)
          },
        },
        requestsGet: offsetLimitPagination({
          field: 'requests',
          keyArgs: ['input', ['filter']],
          useOffsetLimitRead: true,
        }),
        requestCommentsGet: offsetLimitPagination({
          field: 'requestComments',
          keyArgs: ['input', ['filter']],
          paginationInfoHandler: (merged: DsarRequestCommentsGetPayload) => {
            return {
              ...merged?.paginationInfo,
              offset: 0,
              limit: merged?.requestComments?.length,
            }
          },
        }),
        requestHistoryItemsGet: offsetLimitPagination({
          field: 'requestHistoryItems',
          keyArgs: ['input', ['filter']],
        }),
        requestConversationsGet: offsetLimitPagination({
          field: 'conversations',
          keyArgs: ({ input }) => {
            const { filter } = input ?? {}
            const inputArg = {
              input: {
                filter,
              },
            }

            return hashKeyArgs(inputArg)
          },
        }),
      },
    },
    DsarForm: {
      fields: {
        translations: {
          merge: (_, incoming) => {
            return incoming
          },
        },
      },
    },
    IUserWithEmail: {
      fields: {
        fullName: setFullNameType(),
      },
    },
    DsarRequest: {
      fields: {
        fullName: setFullNameType(),
      },
    },
    DsarRequestHistoryItem: {
      keyFields: ['id', 'uiCode', 'createdAt'],
      fields: {
        id: {
          read: (_, { readField }) => {
            const id = `${readField('uiCode')}_${readField('createdAt')}`
            return id
          },
        },
      },
    },
    DsarRequestMessage: {
      keyFields: ['requestMessageId'],
    },
  }
}
