import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ApolloClientProps } from './types'
import { useAuthToken } from '../use-auth-token'
import { APOLLO_STATE_PROP_NAME } from '/@const'
import { useMemo } from 'react'
import { initializeApollo } from '/@lib'

/**
 * Returns an instance of apollo client.
 *
 * @param {PageProps} pageProps
 * @returns {ApolloClient<NormalizedCacheObject>}
 */
export const useApollo = (
  pageProps,
  props?: ApolloClientProps,
): ApolloClient<NormalizedCacheObject> => {
  const token = useAuthToken((s) => s.token)
  const state = pageProps[APOLLO_STATE_PROP_NAME]

  let authorization = token

  // if auth in headers override
  // token
  if (props?.headers?.authorization) {
    authorization = props?.headers?.authorization
  }

  const store = useMemo(
    () =>
      initializeApollo(state, {
        ...props,
        headers: {
          ...props?.headers,
          authorization,
        },
      }),
    [state, props, authorization],
  )
  return store
}
