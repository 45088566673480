import { TypePolicies } from '@palqee/apollo-client'
import { hashKeyArgs } from './helpers'

export const audience = (): TypePolicies => {
  return {
    Audience: {
      fields: {
        users: {
          keyArgs: hashKeyArgs,
        },
      },
    },
  }
}
