// App Static Routes
export const ACCOUNT_DELETED_ROUTE = '/auth/account-deleted/'
export const EMAIL_VERIFICATION_ROUTE = '/auth/verify-email/'
export const LOGIN = '/auth/login/'
export const MFA_CONFIRM = '/auth/mfa/confirm/'
export const MFA_SETUP = '/auth/mfa/setup/'

// App Dynamic Routes
export const getDashboardPage = (companyId: string) =>
  `/${companyId}/dashboard/`
export const getRegisterPage = (companyId: string) =>
  `/${companyId}/employees/register/`
export const getAuthPageWithCallbackUrl = (callbackUrl: string) =>
  `${LOGIN}?callbackUrl=${callbackUrl}`
export const getSurveyManagerPage = (companyId: string) =>
  `/${companyId}/surveys/manager/`

// External Routes
export const PALQEE_WEBSITE = 'https://palqee.com'

export const PALQEE_PRICING_PAGE_EN =
  'https://content.palqee.com/en-gb/business-size'

export const PALQEE_PRICING_PAGE_PT =
  'https://content.palqee.com/pt-br/business-size'

export const invalidCallbackUrlList: RegExp[] = [/_next\/data\/.*/gi]
