import { ComponentProps, forwardRef } from 'react'
import { dialogFooter } from './dialog.css.ts'
import classNames from 'classnames'

const DialogFooter = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  (props, ref) => {
    const { children } = props

    const classes = classNames('pq-dialog__footer', dialogFooter)

    return (
      <div className={classes} ref={ref}>
        {children}
      </div>
    )
  },
)

DialogFooter.displayName = 'DialogFooter'

export { DialogFooter }
