import { useCallback, useEffect, useState } from 'react'

export const useBroadcastSubscription = <T = Record<string, string>>(): T => {
  const [postMessage, setPostMesage] = useState<T>(null)

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (!event.isTrusted) return

      if (Array.isArray(event.data)) {
        const updatedPostMessage = event.data.reduce(
          (prev, e) => {
            if (e.type !== undefined && e.value !== undefined) {
              prev[e.type] = e.value
            }
            return prev
          },
          { ...postMessage },
        )
        setPostMesage(updatedPostMessage)
      }
    },
    [postMessage],
  )

  useEffect(() => {
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [messageHandler])

  return postMessage
}
