import * as types from './types'

export const initialCurrentCompanyState: types.CurrentCompanyState = {
  company: null,
  companyLogo: null,
}

const handleUpdateCurrentCompany = (
  state: types.CurrentCompanyState,
  action: types.UpdateCurrentCompanyAction,
): types.CurrentCompanyState => {
  const companyUpdate = {
    ...state.company,
    ...action.payload.company,
  }

  return {
    ...state,
    company: companyUpdate,
  }
}

const handleUpdateCurrentCompanyLogo = (
  state: types.CurrentCompanyState,
  action: types.UpdateCurrentCompanyLogoAction,
): types.CurrentCompanyState => {
  return {
    ...state,
    companyLogo: action.payload.dataUrl,
  }
}

const handleResetCurrentCompany = (): types.CurrentCompanyState => {
  return {
    ...initialCurrentCompanyState,
  }
}

const CurrentCompanyReducer = (
  state: types.CurrentCompanyState = initialCurrentCompanyState,
  action: types.CurrentCompanyAction,
) => {
  switch (action.type) {
    case types.CurrentCompanyActionType.UPDATE_CURRENT_COMPANY:
      return handleUpdateCurrentCompany(state, action)

    case types.CurrentCompanyActionType.UPDATE_CURRENT_COMPANY_LOGO:
      return handleUpdateCurrentCompanyLogo(state, action)

    case types.CurrentCompanyActionType.RESET_CURRENT_COMPANY:
      return handleResetCurrentCompany()

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default CurrentCompanyReducer
