import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { APOLLO_STATE_PROP_NAME } from '/@const'

/**
 * Returns the apollo state.
 *
 * @param {ApolloClient<NormalizedCacheObject>} client
 * @returns {NormalizedCacheObject}
 */
export const getApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
): NormalizedCacheObject => {
  return client?.cache?.extract()
}

/**
 *  Adds Apollo State to pageprops
 *
 * @param {ApolloClient<NormalizedCacheObject} client
 * @param {PageProps} pageProps
 * @returns {PageProps} pageProps
 */
export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps,
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client?.cache?.extract()
  }
  return pageProps
}
