import 'src/dialog/dialog.css.ts.vanilla.css?source=LmIzbGNlOTIgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICB3aWR0aDogMTBweDsKICBoZWlnaHQ6IDEwcHg7CiAgYmFja2dyb3VuZDogI2ZmZjsKICBsZWZ0OiB2YXIoLS1iM2xjZTkwKTsKICB0b3A6IHZhcigtLWIzbGNlOTEpOwogIHRyYW5zZm9ybTogcm90YXRlKDQ1ZGVnKTsKfQouYjNsY2U5MyB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogNTAlOwogIGxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgtNTAlLCAtNTAlKTsKICB6LWluZGV4OiAxMDAwOwp9Ci5iM2xjZTk0IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgbWluLXdpZHRoOiA2MDBweDsKICBwYWRkaW5nOiAxZW07CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwp9Ci5iM2xjZTk0IC5wcS1jYXJkX19jb250ZW50IHsKICBwYWRkaW5nOiAwLjVlbTsKfQouYjNsY2U5NSB7CiAgZGlzcGxheTogZmxleDsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7Cn0KLmIzbGNlOTYgewogIG1hcmdpbi1sZWZ0OiAxZW07Cn0KLmIzbGNlOTcgewogIG1hcmdpbi1ib3R0b206IDFlbTsKfQouYjNsY2U5OCB7CiAgZGlzcGxheTogZmxleDsKICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kOwogIHdpZHRoOiAxMDAlOwp9Ci5iM2xjZTk5IHsKICBwYWRkaW5nOiAwOwp9';
export var arrowLeftPx = 'var(--b3lce90)';
export var arrowTopPx = 'var(--b3lce91)';
export var dialogBody = 'b3lce97';
export var dialogContentArrow = 'b3lce92';
export var dialogContentCard = 'b3lce94';
export var dialogContentHeader = 'b3lce98';
export var dialogContentHeaderButton = 'b3lce99';
export var dialogFooter = 'b3lce95';
export var dialogManagerSubmitBtn = 'b3lce96';
export var dialogWrapper = 'b3lce93';