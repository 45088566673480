import * as styleVars from '@common/styles/constants'

import Button from '@common/components/button'
import Image from 'next/image'
import { PALQEE_WEBSITE } from '@common/components/consts'
import styled from '@emotion/styled'
import { useIntl } from '@palqee/intl'
import { useRouter } from 'next/router'

const MobilePage = () => {
  const intl = useIntl()
  const router = useRouter()

  return (
    <MobilePageWrapper className="mobile-page-container">
      <div className="background">
        <Image fill src="/static/images/light-background.jpg" alt="" />
      </div>
      <div>
        <Image
          width="200"
          height="78"
          alt=""
          src="/static/images/logo/logo-palqee-blue.png"
        />
      </div>
      <div className="content">
        <p>
          {intl.formatMessage({
            id: 'NO_MOBILE_P1',
            defaultMessage:
              "Oops.. It seems Palqee isn't compatible with mobile devices yet",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: 'NO_MOBILE_P2',
            defaultMessage:
              'Please use a computer or laptop to access this content',
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: 'NO_MOBILE_P3',
            defaultMessage: 'Your Palqee Team',
          })}
        </p>
      </div>
      <Button onClick={() => router.push(PALQEE_WEBSITE)} className="cta">
        {intl.formatMessage({
          id: 'NO_MOBILE_CTA',
          defaultMessage: 'Return to Palqee.com',
        })}
      </Button>
    </MobilePageWrapper>
  )
}

const MobilePageWrapper = styled.div`
   &.mobile-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    height: 100vh;

    .background {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }

    .content {
      position: relative;
      margin: 20px 0 40px 0;

      p {
        color: ${styleVars.darkerGrey};
      text-align: center;
      font-size: 16px;
      margin: 10px 0 10px 0;
      }
    }

    .cta {
        font-size: 16px;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        background: rgb(255, 105, 109);
        border-radius: 4px;
        padding: 10px 20px;
        font-style: normal;
        color: white;
        width: 225px;
      }
    }
  }
`

export default MobilePage
