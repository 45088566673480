import { TypePolicies } from '@palqee/apollo-client'
import { cleanProperty, hashKeyArgs } from './helpers'
import { surveyStateVar } from '@contexts/survey/state'
import dayjs from 'dayjs'

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { offsetLimitPagination } from '../helpers/pagination'

dayjs.extend(isSameOrBefore)

export const survey = (): TypePolicies => {
  return {
    NamespaceSurveyMutation: {
      merge: true,
    },

    NamespaceSurveyQuery: {
      merge: true,
      fields: {
        datamapResponsesGet: {
          keyArgs: hashKeyArgs,
        },
        get: {
          keyArgs: hashKeyArgs,
        },
        questionsGet: {
          keyArgs: hashKeyArgs,
        },
        sessionsGet: {
          keyArgs: ({ input }) => {
            const { filter, search, order, pagination } = input ?? {}
            const inputArg = {
              input: {
                filter,
                ...cleanProperty(search, 'search'),
                ...cleanProperty(order, 'order'),
                ...cleanProperty(pagination, 'pagination'),
              },
            }

            return hashKeyArgs(inputArg)
          },
        },
        invitationsGet: offsetLimitPagination({
          field: 'invitations',
          useOffsetLimitRead: true,
          keyFields: ['invitationId'],
          keyArgs: ({ input }) => {
            const { filter, search, order } = input ?? {}
            const inputArg = {
              input: {
                filter,
                ...cleanProperty(search, 'search'),
                ...cleanProperty(order, 'order'),
              },
            }

            return hashKeyArgs(inputArg)
          },
        }),
      },
    },
    Survey: {
      fields: {
        subcategories: {
          merge(_, incoming: any[]) {
            return incoming
          },
        },
      },
    },
    SurveySession: {
      fields: {
        isComplete: {
          read: (_, { readField }) => {
            const deadlineAt = readField<Date>('deadlineAt')
            return dayjs(deadlineAt).isSameOrBefore(dayjs())
          },
        },
      },
    },
    SurveySubcategory: {
      fields: {
        questions: {
          merge(_, incoming: any[]) {
            return incoming
          },
        },
      },
    },
    SurveyQuestion: {
      fields: {
        isTemplateQuestion: {
          read: (_, { readField }) => {
            return !!readField('questionTemplateId')
          },
        },
        answerOptions: {
          merge(_, incoming: any[]) {
            return incoming
          },
        },
        responses: {
          merge(_, incoming: any[]) {
            return incoming
          },
        },
      },
    },
    SurveySubcategoryTemplate: {
      fields: {
        isSelected: {
          read: (_, { readField }) => {
            const subcategoryTemplateId = readField('id')
            const foundSelectedSubcategoryTemplate =
              surveyStateVar().selectedSubcategoryTemplates.find(
                (selectedSubcategoryTemplate) =>
                  selectedSubcategoryTemplate?.id === subcategoryTemplateId,
              )
            return foundSelectedSubcategoryTemplate?.isSelected || false
          },
        },
        isConfirmed: {
          read: (_, { readField }) => {
            const subcategoryTemplateId = readField('id')
            const foundSelectedSubcategoryTemplate =
              surveyStateVar().selectedSubcategoryTemplates.find(
                (selectedSubcategoryTemplate) =>
                  selectedSubcategoryTemplate?.id === subcategoryTemplateId,
              )
            return foundSelectedSubcategoryTemplate?.isConfirmed || false
          },
        },
      },
    },
  }
}
