import styled from '@emotion/styled'
import CardFooter from './footer'
import { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import classNames from 'classnames'

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow-wrap: anywhere;
  word-break: normal;
`

const CardContent = (
  props: PropsWithChildren<ComponentPropsWithoutRef<'div'>>,
) => {
  const { children, className, ...rest } = props
  const classes = classNames('pq-card__content', className)

  return (
    <CardContentWrapper className={classes} {...rest}>
      {children}
    </CardContentWrapper>
  )
}

CardContent.Footer = CardFooter

export { CardContent }
