import { TypePolicies } from '@apollo/client'
import { hashKeyArgs } from './helpers'
import { setFullNameType } from '../lib'

export const consent = (): TypePolicies => {
  return {
    NamespaceConsentQuery: {
      merge: true,
      fields: {
        get: {
          keyArgs: ({ input }) => {
            const { filter } = input ?? {}
            const inputArg = {
              input: {
                filter,
              },
            }

            return hashKeyArgs(inputArg)
          },
        },
        formsGet: {
          keyArgs: ({ input }) => {
            const { filter } = input ?? {}
            const inputArg = {
              input: {
                filter,
              },
            }

            return hashKeyArgs(inputArg)
          },
        },
      },
    },
    ConsentCurrent: {
      keyFields: (fields) => {
        const { conditionId, personId } = fields
        return `ConsentCurrent:${hashKeyArgs({ conditionId, personId })}`
      },
    },
    Person: {
      fields: {
        fullName: setFullNameType(),
      },
    },
  }
}
