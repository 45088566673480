import { MutableRefObject, useEffect, useState } from 'react'

export const useResizeObserver = <T extends Element>(
  ref: MutableRefObject<T | null>,
) => {
  const [containerWidth, setContainerWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  )

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0]) {
        setContainerWidth(entries[0].contentRect.width)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(element)

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Manually trigger a resize check when the tab becomes visible
        const width = element.getBoundingClientRect().width
        setContainerWidth(width)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      resizeObserver.unobserve(element)
      resizeObserver.disconnect()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [ref])

  return containerWidth
}
