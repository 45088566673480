import { ComponentProps, forwardRef, useId, useLayoutEffect } from 'react'
import classNames from 'classnames'
import { useFloatingUiContext } from '@palqee/utils'
import { dialogBody } from './dialog.css.ts'

const DialogBody = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  (props, ref) => {
    const { className, children, ...rest } = props
    const { setDescriptionId } = useFloatingUiContext()
    const id = useId()

    // Only sets `aria-labelledby` on the Dialog root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setDescriptionId(id)
      return () => setDescriptionId(undefined)
    }, [id, setDescriptionId])

    const classes = classNames('pq-dialog__body', className, dialogBody)

    return (
      <div className={classes} {...rest} id={id} ref={ref}>
        {children}
      </div>
    )
  },
)

DialogBody.displayName = 'DialogBody'

export { DialogBody }
